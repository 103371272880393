import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db2 } from './firebase'; // Suponiendo que db2 es tu conexión de Firestore exportada desde tu archivo firebase.js


export function Party({ pais, month }) {
  const [events, setEvents] = useState([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDescription, setShowDescription] = useState(false);


  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {

    const partyRef = collection(db2, 'party');

    const q = query(partyRef, where('pais', '==', pais));

    getDocs(q)
    .then((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({
          local: data.local,
          city: data.City,
          music: data.Music,
          cost: data.Cost,
          age: data.Age,
        });        
      });
      const event_dict = {};
      results.forEach((result) => {
      if (!event_dict[result.city]) {
        event_dict[result.city] = [];
      }
      event_dict[result.city].push(result);
  });

  const recommended_countries = Object.values(event_dict);
      setEvents(recommended_countries);
      console.log(events);
    })
      .catch(error => {
        console.error(error);
      });
  };

  const handleNextPageOrCity = () => {
    const totalPages = Math.ceil(events[currentEventIndex].length / 3);
    const nextPage = (currentPage + 1) % totalPages;

    if (nextPage === 0) {
      handleNextCity();
      setCurrentPage(0);
    } else {
      setCurrentPage(nextPage);
    }
  };

  const handlePreviousPageOrCity = () => {
    const totalPages = Math.ceil(events[currentEventIndex].length / 3);
    const previousPage = (currentPage - 1 + totalPages) % totalPages;

    if (previousPage === totalPages - 1 && currentPage === 0) {
      const previousEventIndex = (currentEventIndex - 1 + events.length) % events.length;
      setCurrentEventIndex(previousEventIndex);
      // Aquí, actualizamos el currentPage para que sea la última página de la nueva ciudad
      const newTotalPages = Math.ceil(events[previousEventIndex].length / 3);
      setCurrentPage(newTotalPages - 1);
    } else {
      setCurrentPage(previousPage);
    }
};



  const handleNextCity = () => {
    const nextEventIndex = (currentEventIndex + 1) % events.length;
    setCurrentEventIndex(nextEventIndex);
  };

  const handlePreviousCity = () => {
    const previousEventIndex = (currentEventIndex - 1 + events.length) % events.length;
    setCurrentEventIndex(previousEventIndex);
  };
  const renderEuroSymbols = (cost) => {
    let euroSymbols = '';

    if (cost === 'Low') {
      euroSymbols = '€';
    } else if (cost === 'Medium') {
      euroSymbols = '€€';
    } else if (cost === 'High') {
      euroSymbols = '€€€';
    }

    return euroSymbols;
  };

  const images = ['../party1.webp', '../party2.webp', '../party3.webp', '../party4.webp','../party1.webp', '../party2.webp', '../party3.webp', '../party4.webp', '../party1.webp', '../party2.webp', '../party3.webp', '../party4.webp'];

  return (
    <Card sx={{ maxWidth: 345, minHeight: 560, maxHeight: 560, display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          position: 'relative',
          flexGrow: 1,
          overflow: 'hidden',
          cursor: 'default',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            transition: 'transform 0.5s',
            transform: showDescription ? 'translateY(-90%)' : 'translateY(0)',
          }}
        >
          <img
            src={images[currentEventIndex]}
            style={{
              maxWidth: '100%',
              minHeight: '100%',
              objectFit: 'cover',
            }}
            alt={pais}
          />
    {events[currentEventIndex] && (events.length > 1 || Math.ceil(events[currentEventIndex].length / 3) > 1) && (

<div
        style={{
          position: 'absolute',
          top: '50%',
          right: '2%',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '50%',
        }}
      >
        <IconButton onClick={handleNextPageOrCity}>
          <ArrowForwardIosIcon style={{ color: 'rgba(226, 182, 10)' }} />
        </IconButton>
      </div>
 )}
    {events[currentEventIndex] && (events.length > 1 || Math.ceil(events[currentEventIndex].length / 3) > 1) && (

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '2%',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '50%',
        }}
      >
        <IconButton onClick={handlePreviousPageOrCity}>
          <ArrowBackIosIcon style={{ color: 'rgba(226, 182, 10)' }}/>
        </IconButton>
      </div>
 )}


          <div
            style={{
              position: 'absolute',
              width: '100%',
              bottom: '0px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <Typography variant="h5">{events[currentEventIndex]?.[0]?.city}</Typography>
            <Typography variant="h8">Party places</Typography>
          </div>

          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            {events[currentEventIndex]?.slice(currentPage * 3, currentPage * 3 + 3).map((event, eventIndex) => (
              <div
                key={event.local}
                style={{
                  backgroundColor: 'rgba(226, 182, 10)',
                  color: 'white',
                  padding: '4px',
                  marginTop: '20px',
                  minWidth: '200px',
                  width: '100%',
                  placeContent: 'center',
                  borderRadius: '4px',
                  zIndex: '1',
                  animation: `fadeIn 1s`,
                  textAlign: 'center',
                }}
              >
                <h5 className="underline ">{event.local}</h5>
                <Typography variant="body1">{event.music}</Typography>
                <Typography variant="body1">
                  {renderEuroSymbols(event.cost)} {event.age}
                </Typography>
              </div>
            ))}
          </div>

          
        </div>
      </div>
    </Card>
  );
}

export default Party;
