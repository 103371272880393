import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { countryIcons2 } from '../data/countryData';

const Events = ({ pais, month }) => {
  const [events, setEvents] = useState([]);
  const [showDescription, setShowDescription] = useState(true);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      let monthNumber = getMonthNumber(month);
      let nextYear = currentYear;
      let nextMonth;
      if (typeof month === 'undefined') {
        monthNumber = currentMonth + 1;
        nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
        nextMonth = (currentMonth + 2) % 12;
        if (nextMonth === 0) {
          nextMonth = 12;
        }
      } else {
        nextMonth = monthNumber < 12 ? monthNumber + 1 : 1;
        if (nextMonth === 1) {
          nextYear = currentYear + 1;
        }
      }

      if (monthNumber<12) {
        nextMonth = monthNumber+1;
    }else {
      nextMonth = '01'; 
      nextYear=currentYear+1;
    }
  
      let targetYear = currentYear;
      console.log(currentMonth + ' xxx ' + monthNumber)
      if ((currentMonth+1) > monthNumber) {
        targetYear += 1;
        nextYear=targetYear;
      }
      

      if(monthNumber<10){

        monthNumber='0'+monthNumber;
      if(nextMonth<10){

          nextMonth='0'+nextMonth;
  
        }

      }
      

  
      const musicResponse = await fetchEventsByType('music', monthNumber, targetYear, nextMonth, nextYear, pais);
      const sportsResponse = await fetchEventsByType('music', monthNumber, targetYear, nextMonth, nextYear, pais);
      const theaterResponse = await fetchEventsByType('theater', monthNumber, targetYear, nextMonth, nextYear, pais);
  
      const eventsByType = {
        music: musicResponse._embedded?.events || [],
        sports: sportsResponse._embedded?.events || [],
        theater: theaterResponse._embedded?.events || [],
      };
  
      let combinedEvents = [];
  
      // Agregar un evento de cada tipo si están disponibles
      Object.values(eventsByType).forEach((events) => {
        if (events.length > 0) {
          combinedEvents.push(events[0]);
        }
      });
  
      // Si no se tienen eventos de cada tipo, realizar llamada adicional
      if (combinedEvents.length < 3) {
        const additionalEvents = await fetchAdditionalEvents(monthNumber, targetYear, nextMonth, nextYear, pais);
        combinedEvents = [...combinedEvents, ...(additionalEvents._embedded?.events || [])];
      }
  
      // Filtrar eventos duplicados
      const uniqueEvents = filterDuplicateEvents(combinedEvents);
  
      setEvents(uniqueEvents.slice(0, 3));
    } catch (error) {
      console.error(error);
    }
  };
  

  const filterDuplicateEvents = (events) => {
    const filteredEvents = [];
    const uniqueNames = new Set();
    const uniqueGenres = new Set();

    for (const event of events) {
      const eventName = event.name;
      const eventGenres = event.classifications.map((classification) => classification.genre.name);

      let isUnique = true;

      if (uniqueNames.has(eventName) || hasDuplicateGenres(eventGenres, uniqueGenres)) {
        isUnique = false;
      }

      if (isUnique) {
        filteredEvents.push(event);
        uniqueNames.add(eventName);
        eventGenres.forEach((genre) => uniqueGenres.add(genre));
      }

      if (filteredEvents.length === 3) {
        break;
      }
    }

    return filteredEvents;
  };

  const hasDuplicateGenres = (eventGenres, uniqueGenres) => {
    for (const genre of eventGenres) {
      if (uniqueGenres.has(genre)) {
        return true;
      }
    }
    return false;
  };

  const getMonthNumber = (monthName) => {
    const monthMap = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12
    };

    return monthMap[monthName] || 1;
  };

  const fetchEventsByType = async (type, month, year, nextMonth, nextYear, pais) => {
    const url = `https://app.ticketmaster.com/discovery/v2/events.json?countryCode=${countryIcons[pais]}&startDateTime=${year}-${month}-01T00:00:00Z&endDateTime=${nextYear}-${nextMonth}-01T00:00:00Z${
      type ? `&classificationName=${type}` : ''
    }&size=1&sort=relevance,desc&apikey=${process.env.REACT_APP_TICKETMASTER_API_KEY}`;    
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const fetchAdditionalEvents = async (month, year, nextMonth, nextYear, pais) => {
    const url = `https://app.ticketmaster.com/discovery/v2/events.json?countryCode=${countryIcons[pais]}&startDateTime=${year}-${month}-01T00:00:00Z&endDateTime=${nextYear}-${nextMonth}-01T00:00:00Z&size=200&sort=relevance,desc&apikey=${process.env.REACT_APP_TICKETMASTER_API_KEY}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const filterEventName = (eventName) => {
    const nameParts = eventName.split('-');
    return nameParts[0].trim();
  };

  return (
    <div>
    {events.length > 0 && ( // <-- Aquí está el cambio. Este código solo se ejecutará si hay eventos.
      <Card sx={{ maxWidth: 345, minHeight: 560, maxHeight: 560, display: 'flex', flexDirection: 'column' }}>
        {events.map((event) => (
          <a href={event.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', cursor: 'pointer' }}>
            <CardMedia sx={{ height: 187, position: 'relative' }}>
              <div style={{ height: '100%', display: 'flex', flexDirection: 'row', width: '100%' }}>
                {event.images && event.images[0] && (
                  <img
                    src={event.images[0].url}
                    style={{ maxWidth: '100%', minHeight: '100%', objectFit: 'cover' }}
                    alt={event.name}
                  />
                )}
                {showDescription && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '10px',
                      backgroundColor: 'rgba(226,182,10)',
                      padding: '10px',
                      borderRadius: '5px',
                      color: 'white', // Color del texto
                    }}
                  >
                    <Typography variant="body1" color="inherit">
                      {filterEventName(event.name)} - {event._embedded?.venues[0]?.city?.name}
                    </Typography>
                  </div>
                )}
              </div>
            </CardMedia>
          </a>
        ))}
      </Card>
     )}
     </div>
   );
};
const countryIcons = countryIcons2;
export default Events;
