import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { IconButton, Grid, Box } from '@mui/material';
import LocationOn from '@mui/icons-material/LocationOn';
import { ProjectCardImages } from './ProjectCardImages';
import Carousel from 'react-bootstrap/Carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RestaurantIcon from '@mui/icons-material/Restaurant'; // Import the icon you want to use

// Import Dialog components from Material UI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Import firebase
import 'firebase/firestore';
import { query, where, getDocs, collection } from "firebase/firestore";
import { db2 } from './firebase';

export function FeatureCard({ choose, pais, month }) {
  const [posts, setPosts] = useState([]);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  

  const handleDoNotShowAgainChange = (event) => {
    setDoNotShowAgain(event.target.checked);
    localStorage.setItem('doNotShowAgain', event.target.checked);
  };

  const handleLocationClick = (location) => {
    const doNotShowAgain = localStorage.getItem('doNotShowAgain');
    if (doNotShowAgain === 'true') {
      window.open(`https://www.google.com/maps/place/${location}`, "_blank");
    } else {
      setDialogOpen(true);
      setRedirectUrl(`https://www.google.com/maps/place/${location}`);
    }
  };

  useEffect(() => {
    handleContinue();
  }, []);

  const handleContinue = async () => {

    let q;
    let q2;


    switch(choose) {
        case 'food':
          q = query(collection(db2, "foodCountry"), where("pais", "==", pais));
          const querySnapshot = await getDocs(q);
          const veganQuery = query(collection(db2, 'vegan'), where("pais", "==", pais));
          const veganResults = await getDocs(veganQuery);
        
          const recommended_countries = querySnapshot.docs.map(doc => ({
            'food': doc.data().food,
            'restaurant': doc.data().restaurant,
            'descriptionFoodEn': doc.data().descriptionFoodEn,
          }));
        
          const vegan_info = veganResults.docs.map(doc => ({
            'food': "vegetarian and vegan",
            'restaurant': 'Vegetarian: ' + doc.data().exampleVegetarin + ' Vegan: '+ doc.data().exampleVegan +  ' \nPossibility of finding vegan food: ' + doc.data().veganIndex,
            'descriptionFoodEn': doc.data().description,
          }));
        
          setPosts([...recommended_countries, ...vegan_info]);
          break;
      
        case 'interest':
        case 'nature':
            if(choose=="nature") q2="Natural";
            if (choose=="interest") q2="Cultural";
            q = query(collection(db2, "featureCard"), where("pais", "==", pais), where("typeNC", "==", q2));
            const querySnapshot2 = await getDocs(q);
            setPosts(querySnapshot2.docs.map(doc => doc.data()));

            let posts = querySnapshot2.docs.map(doc => doc.data());

            // Ordenar por 'region' y luego por 'city'
            posts.sort((a, b) => {
              if (a.region < b.region) return -1;
              if (a.region > b.region) return 1;
              if (a.city < b.city) return -1;
              if (a.city > b.city) return 1;
              return 0;
            });

            setPosts(posts);
            break;        
    }
  };
  //        setPosts(data);


  const handleClose = () => {
    setDialogOpen(false);
    window.open(redirectUrl, "_blank");
  };

  const handleNext = () => {
    const nextIndex = currentPostIndex === posts.length - 1 ? 0 : currentPostIndex + 1;
    setCurrentPostIndex(nextIndex);
  }
  
  const handlePrev = () => {
    const prevIndex = currentPostIndex === 0 ? posts.length - 1 : currentPostIndex - 1;
    setCurrentPostIndex(prevIndex);
  }

  return (
    <>

      {posts.length > 0 && (
        <Card sx={{ maxWidth: 345, minHeight: 560, maxHeight: 560, display: 'flex', flexDirection: 'column' }}>
          <Box flex="1 0 auto">
          <Grid container style={{ position: 'relative' }}>
  
          <Carousel
  activeIndex={currentPostIndex}
  onSelect={(selectedIndex) => setCurrentPostIndex(selectedIndex)}
  interval={null} // Aplicar la velocidad del carrusel
  touch={true}
  controls={false}
>     
{posts.map((post, index) => (
              <Carousel.Item key={index}>
                {/* Solo cargar la imagen si es la imagen actual, la siguiente o la anterior */}
                {(index === currentPostIndex || index === currentPostIndex + 1 || index === currentPostIndex - 1) && (
                  <>
                    <CardMedia>
                      {choose === 'food' && <ProjectCardImages pais={pais} category={post.food} visible={true} choose={choose} />}
                      {(choose === 'interest' || choose === 'nature') && <ProjectCardImages pais={pais} category={post.atraction} visible={true} choose={choose}/>}
                    </CardMedia>
                <CardContent style={{ overflow: 'auto', maxHeight: 200 }}> 
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      {choose === 'food' && (
                        <>
                          <Typography variant="h5">{post.food}</Typography>
                          {post.food == "vegetarian and vegan" && 
                          <>
                          {post.restaurant.split('\n').map((item, key) => {
                            return <React.Fragment key={key}>{item}<br/></React.Fragment>
                          })} 
                          </>
                        }                     
                         {post.food !== "vegetarian and vegan" && 
                            post.restaurant.split(',').map((restaurant, index) => 
                            <>
                            {restaurant}
                              <IconButton key={index} onClick={() => handleLocationClick(restaurant)}>
                                <LocationOn />
                              </IconButton>
                              </>
                            )
                          }

                          <Typography variant="body2" color="text.secondary">
                            <strong>{post.descriptionFoodEn}</strong>
                            <br />
                          </Typography>
                        </>
                      )}
                      {(choose === 'interest' || choose === 'nature') && (
                        <>
                          <Typography variant="h5">{post.atraction}</Typography>
                          <Typography variant="h8">
                            {post.city} / {post.time} hours
                            <IconButton onClick={() => handleLocationClick(post.atraction + " ," + post.city)}>
                              <LocationOn />
                            </IconButton>
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <strong>{post.description}</strong>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                </>
                )}
              </Carousel.Item>
            ))}
          </Carousel>
          <IconButton 
    onClick={handlePrev}
    style={{
      position: 'absolute',
      top: '200px',
      left: '2%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '50%',
    }}
  >
    <ArrowBackIosIcon style={{ color: 'rgba(226, 182, 10)' }}/>
  </IconButton>
          <IconButton 
    onClick={handleNext}
    style={{
      position: 'absolute',
      top: '200px',
      right: '2%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '50%',
    }}
  >
    <ArrowForwardIosIcon style={{ color: 'rgba(226, 182, 10)' }} />
  </IconButton>
</Grid>
          </Box>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {currentPostIndex + 1} / {posts.length}
            </Typography>
          </Box>

          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
          >
            <DialogTitle id="alert-dialog-title">{"Attention"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The location is not exact. Once you are addressed to Google Maps, please, click on the lens icon.
                <br></br>
                <br></br>
                {/*You can also follow the list of the country with all the points of interest:
                <br></br>
                <a href="https://goo.gl/maps/2AFP9r8oP2PWsfER7" target="_blank">View full List</a>
  */}
              </DialogContentText>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doNotShowAgain}
                    onChange={handleDoNotShowAgainChange}
                    name="doNotShowAgain"
                  />
                }
                label="Do not show this message again"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Accept
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )}
    </>
  );
}

export default FeatureCard;
