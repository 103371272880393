import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Country from './Country'; 
import reportWebVitals from './reportWebVitals';
import PrivacyPolicy from './PrivacyPolicy';
import { Flights } from './components/Flights';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter>
    <Routes>
      <Route path='/' element={<App></App>}/>
            <Route path="/CountrySelected/:pais" element={<Country></Country>} />
            <Route path="/flights" element={<Flights></Flights>} />
            <Route path="/Privacy-Policy" element={<PrivacyPolicy></PrivacyPolicy>} />
        </Routes>
   </BrowserRouter>
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
