import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last Updated: 27/07/2023</p>

      <p>
        At Jomajola, we respect and protect the privacy of our users. This privacy policy describes how we collect, use, and protect the personal information you may provide while visiting our website. By accessing and using our website, you agree to the terms and conditions set forth in this privacy policy. If you do not agree with our policy, please do not use our website.
      </p>

      <h2>Collection and Use of Information</h2>

      <p>
        <strong>Automatically Collected Data:</strong> Like many other websites, we collect certain information automatically when users interact with our site. This information may include the user's IP address, browser type, device used, pages visited, visit time, and other usage data. We use this information to improve the user experience, analyze trends, and administer the site.
      </p>

      <p>
        <strong>Cookies:</strong> We use cookies to collect information and enhance the user experience. A cookie is a small file stored on the user's browser that allows our website to recognize the user on future visits. Cookies can be used to remember preferences, offer personalized content, and conduct analysis. By using our website, you agree to the use of cookies in accordance with our cookie policy.
      </p>

      <p>
        <strong>Local Storage:</strong> We maintain certain data in your browser's local storage to enhance the functionality of the website. This data may include user preferences and other settings.
      </p>

      <p>
        <strong>Google AdSense and Google Analytics:</strong> Our website uses Google AdSense and Google Analytics, which are services provided by Google. These services may collect information and use cookies to display relevant ads and perform analysis on website activity. You can review Google's privacy policy for more information on how Google collects and uses data.
      </p>

      <h2>Information Security</h2>

      <p>
        We maintain security measures to protect the personal information of our users. However, please be aware that no data transmission over the Internet or electronic storage can guarantee absolute security. While we strive to protect user information, we cannot guarantee its complete security.
      </p>

      <h2>Links to Third-Party Sites</h2>

      <p>
        Our website may contain links to third-party sites that are not under our control. We are not responsible for the privacy practices of these websites. We recommend reviewing the privacy policies of those sites before providing any personal information.
      </p>

      <h2>Changes to this Privacy Policy</h2>

      <p>
        We reserve the right to modify this privacy policy at any time. Any changes will be posted on this page with a new update date. We recommend periodically reviewing this policy to stay informed about how we protect your information.
      </p>

      <h2>Contact</h2>

      <p>
        If you have any questions or concerns about our privacy policy or the handling of your data, please contact us at [contact address or email].
      </p>
    </div>
  );
};

export default PrivacyPolicy;
