import React, { useState, useEffect } from 'react';
import { Row, Col} from "react-bootstrap";

import 'firebase/firestore';
import { query, where, getDocs, collection, and } from "firebase/firestore";
import { db2 } from './firebase';

import { GppBad, GppGood, GppMaybe, ThumbDown, ThumbUp, ThumbsUpDown } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';

export const PublicTransport = ({ pais }) => {
  const [posts, setPosts] = useState([]);


  useEffect(() => {
    handleContinue();
  }, []);

  const handleContinue = async () => {
    let q;
    const countriesRef = collection(db2, "publicTransport");
    q = query(countriesRef, where("pais", "==", pais));
    getDocs(q)
    .then((querySnapshot) => {
      const countries = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        countries.push(data);
        
      });
      setPosts(countries);
    })
      .catch(error => {
        console.error(error);
      });
  };

  const getIconByValue = (value) => {
    switch (value) {
      case 'Good':
        return <ThumbUp  fontSize="large" className='transport-icons' />;
      case 'Regular':
        return <ThumbsUpDown  fontSize="large" className='transport-icons' />;
      case 'Poor':
        return <ThumbDown  fontSize="large" className='transport-icons2' />;
      case 'Excellent':
        return <ThumbUp  fontSize="large" className='transport-icons2' />;
      default:
        return <ErrorIcon />;
    }
  };
  const getSafetyIcon = (value) => {
    switch (value) {
      case 'Good':
        return <GppGood  fontSize="large" className='transport-icons' />;
      case 'Regular':
        return <GppMaybe  fontSize="large" className='transport-icons' />;
      case 'Bad':
        return <GppBad f ontSize="large" className='transport-icons2' />;
      case 'Excellent':
        return <GppGood  fontSize="large" className='transport-icons2' />;
      default:
        return <ErrorIcon />;
    }
  };
  return (
    <section className="budget border" id="publicTransport">
      <div className="col-12 ">
        <div className="budget-bx">
          <h4>Public Transport</h4>
          <Row>
            {posts.length > 0 ? (
              posts.map((transport, index) => (
               <>
                            <Col size={1} md={3} xs={6} key="city" className='placeCenter2'>
                              <div className='prueba'>Urban</div>
                              {getIconByValue(transport.cityConnectivity)}
                            </Col>
                            <Col size={1} md={3} xs={6} key="rural" className='placeCenter2'>
                            <div className='prueba'>Rural</div>
                            {getIconByValue(transport.ruralConnectivity)}
                            </Col>
                            <Col size={1} md={3} xs={6} key="Intercity" className='placeCenter2'>
                            <div className='prueba'>Intercity</div>
                            {getIconByValue(transport.intercity)}
                            </Col>
                            <Col size={1} md={3} xs={6} key="safety" className='placeCenter2'>
                            <div className='prueba'>Safety</div>
                            {getSafetyIcon(transport.safety)}                            
                            </Col>
                            <Col md={12} className='placeCenter2'>
                    <div className='prueba' style={{ marginTop: '20px' }}>Type: {transport.transport}</div>
                    <div className='prueba' style={{ marginTop: '20px' }}>Advise: {transport.disadvantages}</div>
                  </Col>  
                    </>
              ))
            ) : (
              <p>Loading...</p>
            )}
                                </Row>

        </div>
      </div>
      
    </section>
  );
};
