import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db2 } from './firebase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ForumIcon from '@mui/icons-material/Forum';
import GavelIcon from '@mui/icons-material/Gavel';
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

export const TipsTXT = ({ pais }) => {
  const [posts, setPosts] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    handleContinue();
  }, []);

  const handleContinue = async () => {
    try {
      const countriesRef = collection(db2, 'tips2');
      const q = query(countriesRef, where('country', '==', pais));
      const querySnapshot = await getDocs(q);
      const countries = querySnapshot.docs.map((doc) => doc.data());
      setPosts(countries);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const createStopoverItem = (icon, title) => (
    <Col key={title} className="mb-3">
      <div className="stopover-item">
        {React.cloneElement(icon, { color: '#E2B60A', fontSize: 'large' })}
        <div className="prueba mt-2">{title}</div>
      </div>
    </Col>
  );
  const formatLawText = (text) => {
    const parts = text.split(/(\d+\.\s)/g);
    return parts.map((part, index) => (
      <>
        {index % 2 === 0 ? null : <br />}
        {index % 2 === 1 ? <span style={{ color: '#E2B60A' }}>{part}</span> : part}
      </>
    ));
  };
  
  

/* <h3>Tips
            <button onClick={handleToggleMinimize} style={{ fontSize: '2rem', padding: '1rem' }}>
              {isMinimized ? <ArrowDropDownIcon fontSize="inherit" /> : <ArrowDropUpIcon fontSize="inherit" />}
            </button>
          </h3>*/
  return (
    <section className="budget border" id="tips">
        <div className="budget-bx ">
          {!isMinimized && (
            <>
              {posts.length > 0 && posts.map((transport, index) => (
                <>
                <Row key={index}>
                  
          <div className="stopover-item">
        <GavelIcon style={{ color: '#E2B60A' }}></GavelIcon>
        <div className="prueba mt-2">
          {formatLawText(transport.law)}
   </div>
      </div>

                </Row>
                

                </>

              ))}
            </>
          )}
        </div>
    </section>
  );
};
