import React, { useState, useEffect } from 'react';
import FlightIcon from '@mui/icons-material/Flight';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { countryIcons2, toRotate2 } from '../data/countryData';
import airportsData from '../data/airports';

function FlightInfo({ dateFrom, dateTo, flyFrom, locations, destination, daysMin, daysMax }) {
  if (daysMin < 2) daysMin = 2;
  if (daysMax < 3) daysMax = 3;

  function getIsoCountryByIataCode(iata) {
    const airport = airportsData.find(airport => airport.iata_code === iata);
    return airport ? airport.iso_country : null;  // Devuelve null si no encuentra el aeropuerto
  }
  function getCountryNameByIsoCode(isoCode) {
    return Object.keys(countryIcons2).find(country => countryIcons2[country] === isoCode) || null;
  }
  function countryExistsInToRotate(countryName) {
    return toRotate2.includes(countryName);
  }
  let flyFrom2=flyFrom;
  if(flyFrom.length===3){
    flyFrom2 = getIsoCountryByIataCode(flyFrom);
  }
 
  const baseReferee = `https://www.kiwi.com/en/nomad/results/${flyFrom2}~${dateFrom}_${dateTo}~--/${flyFrom2}~--~1-60/`;

  const [noResults, setNoResults] = useState(false);
  const [stopovers, setStopovers] = useState(1);
  const [flightData, setFlightData] = useState({
      data: null,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  function transformDate(dateStr) {
      const [year, month, day] = dateStr.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      return encodeURIComponent(formattedDate);
  }

  const formattedLocations = locations.map(location => {
    if (location.value.length === 3) {
      const countryCode = getIsoCountryByIataCode(location.value);
      return `${countryCode}~--~3-5/`;
    } else {
      return `${location.value}~--~3-5/`;
    }
  });
  
  let formattedDestination;

  if (destination.length === 3) {
      const countryCode = getIsoCountryByIataCode(destination);
      formattedDestination = `${countryCode}~--~${daysMin}-${daysMax}`;
  } else {
      formattedDestination = `${destination}~--~${daysMin}-${daysMax}`;
  }
    
  // Concatenar la URL base con las locaciones formateadas
  const referee2 = `${baseReferee}${formattedLocations.join('')}${formattedDestination}?nomad_stopNumber=${stopovers}%7Etrue&cabinClass=ECONOMY-false&affilid=jomajoladeeplinkjomajola`;

  // Concatenar la URL base con las locaciones formateadas
  const specialObject = {
    locations: [destination],
    nights_range: [daysMin, daysMax]
};
const viaLocations = [specialObject, ...locations.map(location => ({
    locations: [location.value],
    nights_range: [1, 5]
}))];

const requestData = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'apikey': process.env.REACT_APP_TEQUILA_API_KEY
    },
    body: JSON.stringify({
        via: viaLocations
    })
};
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
useEffect(() => {
        const apiUrlBase = `https://api.tequila.kiwi.com/v2/nomad?adults=1&sort=quality&limit=3&date_from=${transformDate(dateFrom)}&date_to=${transformDate(dateTo)}&fly_from=${flyFrom}&fly_to=${flyFrom}`;
        const apiUrl1Stop = `${apiUrlBase}&max_stopovers=1`;
        const apiUrl2Stop = `${apiUrlBase}&max_stopovers=2`;

        const fetchData = (url) => {
            fetch(url, requestData)
                .then(response => response.json())
                .then(data => {
                    if (!data.data || data.data.length === 0) {
                        if (url === apiUrl1Stop) {
                            setStopovers(2); // Actualiza el estado a 2 escalas
                            fetchData(apiUrl2Stop);
                        } else {
                            setNoResults(true);
                        }
                    } else {
                        setFlightData({
                            data: data.data,                            
                        });


                    }
                })
                .catch(error => {
                    console.error('Hubo un problema con la petición fetch:', error);
                    setNoResults(true);
                });
        };

        fetchData(apiUrl1Stop);

    }, []);

    if (noResults) {
        return <div>Sorry, there isn't any combination for the selected locations. Please, try with main airports.</div>;
    }

    if (!flightData.data) return <div>Loading...</div>;

    const affilid = "jomajoladeeplinkjomajola";
    const referee = `https://www.kiwi.com/booking?token=XXXXXXX&affiliate_id=${affilid}`;

  const getTimeDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let difference = Math.abs(endDate.getTime() - startDate.getTime());
    const hours = Math.floor(difference / 3600000);
    difference -= hours * 3600000;
    const minutes = Math.floor(difference / 60000);
    return `${hours}h ${minutes}m`;
};
function formatDateToCustom(isoDate) {
  const date = new Date(isoDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthName = months[date.getUTCMonth()];
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${day}-${monthName} ${hours}:${minutes}h`;
}

const theme = createTheme({
  palette: {
    neutral: {
      main: '#2290ac',
      contrastText: '#fff',
    },
  },
});


return (
  <>
      <div 
          className="flightsContainer" 
          style={{
              backgroundColor: 'white', 
              color: 'black', 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              overflowX: isMobile ? 'visible' : 'auto'
          }}
      >      
          {flightData.data.map((flight, index) => {
              const specificReferee = `https://www.kiwi.com/booking?token=${flight.booking_token}&affiliate_id=${affilid}`;
              return (
                  <div key={index} style={{ padding: "10px", background:'', display: 'inline-flex', flexDirection: 'column'}}>
                      <div className='border3'>
                          {flight.route.map((segment, segmentIndex) => (
                              <>
                                  {/* Información general del segmento */}
                                  <div className='testContainer' style={{background:'#E8F4FD'}} >
                                      <div className='itemFrom ml-2' >
                                          <p><strong>{segment.cityFrom}</strong></p>
                                      </div>
                                      <div className='itemDate1 ml-2'>
                                          <p style={{ fontSize: '0.8rem'}}>{formatDateToCustom(segment.local_departure)}</p>
                                      </div>
                                      <div className='itemFlow' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                          <div>
                                              <p style={{fontSize: '0.8rem', marginBottom:'0px'}}>{getTimeDifference(segment.utc_departure, segment.utc_arrival)}</p>
                                          </div>
                                          <div>
                                              <FlightIcon style={{transform: 'rotate(90deg)', fontSize: '1rem'}} color="inherit" />
                                          </div>
                                          {segment.route.length >1 && (
                                              <p style={{fontSize: '0.8rem' }}>
                                                  {segment.route.slice(0, -1).map(stopover => stopover.cityCodeTo).join(", ")}
                                              </p>
                                          )}
                                      </div>
                                      <div className='itemTo ml-2'>
                                          <p><strong>{segment.cityTo}</strong></p>
                                      </div>
                                      <div className='itemDate2 ml-2'>
                                          <p style={{ fontSize: '0.8rem' }}>{formatDateToCustom(segment.local_arrival)}</p>
                                      </div>
                                  </div>

                                  {/* Sólo renderiza el ícono de la luna y el número de noches si no es el último segmento */}
                                  {segmentIndex !== flight.route.length - 1 && (
                                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '2px', width: '100%', background: 'white'}}>
                                          {segment.route.slice(0, -1).some(stopover => stopover.cityTo === flight.route[segmentIndex + 1].cityFrom) && (
                                              <p style={{fontSize: '0.8rem', textAlign: 'center', marginTop: '5px', marginBottom:'0px'}} className='border2'>
                                                  You can get off at {flight.route[segmentIndex + 1].cityFrom}
                                              </p>
                                          )}
                                          <BedtimeIcon color="inherit" className='mt-2' style={{ color: '#E2B60A' }} />
                                          <p style={{fontSize: '0.8rem' }}>
                                              { 
                                                  // Calcular el número de noches entre segmentos
                                                  Math.floor((new Date(flight.route[segmentIndex + 1].local_departure) - new Date(segment.local_arrival)) / (1000 * 60 * 60 * 24))
                                              } nights
                                          </p>
                                      </div>
                                  )}
                              </>
                          ))}
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                              <ThemeProvider theme={theme}>
                                  <a href={specificReferee} target="_blank" rel="noopener noreferrer" style={{ display: "inline-block", textDecoration: "none", margin: '10px' }}>
                                      <Button className="mt-2 yellowBackground" variant="contained" color='neutral' style={{ padding: "10px", backgroundColor: "#007bff", color: "white", borderRadius: "5px"}}>
                                          &nbsp;Book &nbsp;<strong> {flight.price} {flight.currency}</strong>
                                      </Button>
                                  </a>
                              </ThemeProvider>
                          </div>
                      </div>
                  </div>
                  
              );
          })}
          
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

      <a href={referee2} target="_blank" rel="noopener noreferrer" style={{ display: "inline-block", textDecoration: "none", margin: '10px' }}>
                                      <Button className="mt-2 yellowBackground" style={{ padding: "10px", backgroundColor: "#007bff", color: "white", borderRadius: "5px"}}>
                                          &nbsp;See more Flights
                                      </Button>
                                  </a>
                                  </div>

  </>
);

}

export default FlightInfo;