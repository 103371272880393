import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCardMini } from "./ProjectCardMini";
import { useEffect } from "react";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box } from '@mui/material';
import { FilterListRounded } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Checkbox, FormControlLabel, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import 'firebase/firestore';
import { query, where, getDocs, collection} from "firebase/firestore";
import { db2 } from './firebase';
import { IconButton } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import { countryIcons2, toRotate2 } from '../data/countryData';
import airportsData from '../data/airports';
import WidgetComponent3 from "./WidgetComponent3";
import dayjs from 'dayjs';

const FilterButton = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#616161',  // color por defecto
  '&:hover': {
    color: '#3f51b5',  // color al hacer hover
  },
}));

export const Projects = () => {
  const [pais, setPais] = useState([]);
  const [pais2, setPais2] = useState([]);
  let location3;
  const [selectedContinent, setSelectedContinent] = useState([]);
  const [userManuallySelectedContinent, setUserManuallySelectedContinent] = useState(false);
  const [selectAllContinent, setSelectAllContinent] = useState(false);
  const [selectedNature, setSelectedNature] = useState([]);
  const [selectAllNature, setSelectAllNature] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [trigger, setTrigger] = useState(0); // Nueva variable de estado
  const [hasRemovedCountries, setHasRemovedCountries] = useState(false);
  const [removedCountry, setRemovedCountry] = useState(null); // Nueva variable de estado
  const [buttonLabel, setButtonLabel] = useState('Order by Flights'); // Inicializa con la primera opción
  const [selectedDateBefore, setSelectedDateBefore] = useState(new Date());
  const [selectedDateAfter, setSelectedDateAfter] = useState(new Date());
  const [returnDateBefore, setReturnDateBefore] = useState(new Date());
  const [returnDateAfter, setReturnDateAfter] = useState(new Date());
  let selectedMonths = localStorage.getItem('selectedMonths');
  let location = localStorage.getItem('location');
  const art = parseFloat(localStorage.getItem('Art')) || 0.25;
  const aventure = parseFloat(localStorage.getItem('Aventure')) || 0.25;
  const entertainment = parseFloat(localStorage.getItem('Entertainment')) || 0.25;
  const historical = parseFloat(localStorage.getItem('Historical')) || 0.25;
  const nature = parseFloat(localStorage.getItem('Nature')) || 0.25;
  const nightLife = parseFloat(localStorage.getItem('Nightlife')) || 0.25;
  const [allCountries, setAllCountries] = useState([]);
  const [loaded, setLoaded] = useState(false); // Actualizar la inicialización de loaded
  let tripDuration = localStorage.getItem('tripDuration');
  let selectedDate = localStorage.getItem('departureDate');
  let marginDays =localStorage.getItem('marginDays');
  let location4 = JSON.parse(localStorage.getItem('location')).value;


  const handleAccept = () => {
    setOpenDialog(false);
    setOpenDialog2(false);
  };
  const handleButtonClick = () => {
    setButtonLabel((prevLabel) => (prevLabel === 'Order by Flights' ? 'Order by Likes' : 'Order by Flights'));

    // Agrega aquí la lógica que deseas realizar cuando se haga clic en el botón
  };
  
  const handleSelectAllContinent = (event) => {
    const { checked } = event.target;
    setSelectAllContinent(checked);
    if (checked) {
      setSelectedContinent(['America', 'Europa', 'Asia', 'Africa', 'Oceania']);
      setUserManuallySelectedContinent(true);
    } else {
      setSelectedContinent([]);
    }
  };
  
  const handleSelectAllNature = (event) => {
    const { checked } = event.target;
    setSelectAllNature(checked);
    if (checked) {
      setSelectedNature(['Beach', 'Mountain']);
    } else {
      setSelectedNature([]);
    }
  };
  const { ref, inView } = useInView({
    threshold: 0.2,  // Activa inView tan pronto como el elemento entra en la vista
  });

  const handleMenuItemClick = (value) => {
    if (selectedContinent.includes(value)) {
      setSelectedContinent((prevSelected) => prevSelected.filter((item) => item !== value));
      setUserManuallySelectedContinent(true);


    } else {
      setSelectedContinent((prevSelected) => [...prevSelected, value]);
      setUserManuallySelectedContinent(true);

    }
  };
  const handleMenuItemClick2 = (value) => {
    if (selectedNature.includes(value)) {
      setSelectedNature((prevSelected) => prevSelected.filter((item) => item !== value));
    } else {
      setSelectedNature((prevSelected) => [...prevSelected, value]);
    }
  };
  useEffect(() => {
    const removedCountries = getRemovedCountries();
    const filteredCountries = allCountries.filter((country) => {
      const removedCountry = removedCountries.find((removed) => removed.pais === country.pais);
      return !removedCountry;
    });
  
    setPais(filteredCountries.slice(0, 3));
    console.log(pais);
        setPais2(filteredCountries.slice(3, 7));
  }, [allCountries]);
  
  
  const removeCountry = (countryToRemove) => {
    setHasRemovedCountries(true);

    if (!dontShowAgain) {
      setRemovedCountry(countryToRemove); // Establecer el país eliminado
      setOpenDialog(true);
    }
    const removedCountries = getRemovedCountries();
  
    // Verificar si el país ya está en la lista de países eliminados
    const existingCountry = removedCountries.find((removed) => removed.pais === countryToRemove.pais);
    if (existingCountry) {
      return; // Si el país ya está en la lista, no hacer nada
    }
  
    // Agregar el país a la lista de países eliminados y almacenarlo en el localStorage
    const updatedRemovedCountries = [...removedCountries, countryToRemove];
    localStorage.setItem('removedCountries', JSON.stringify(updatedRemovedCountries));
  
    setAllCountries(allCountries.filter((country) => country !== countryToRemove));
  };

  

  const Filters = () => (
    <div style={{ display: 'flex' }}>
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'text.primary',  // color del texto
          backgroundColor: 'background.paper',  // color de fondo
          border: '1px solid #ccc',  // borde
          borderRadius: 2,
          p: 1,
          marginRight: '10px',  // espacio entre las cajas
        }}
      >
        <p>Continents:</p>
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectAllContinent}
            onChange={handleSelectAllContinent}
          />
        }
        label="All"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedContinent.includes('America')}
            onChange={() => handleMenuItemClick('America')}
          />
        }
        label="America"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedContinent.includes('Europa')}
            onChange={() => handleMenuItemClick('Europa')}
          />
        }
        label="Europe"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedContinent.includes('Asia')}
            onChange={() => handleMenuItemClick('Asia')}
          />
        }
        label="Asia"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedContinent.includes('Africa')}
            onChange={() => handleMenuItemClick('Africa')}
          />
        }
        label="Africa"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedContinent.includes('Oceania')}
            onChange={() => handleMenuItemClick('Oceania')}
          />
        }
        label="Oceania"
      />
    </Box>
    <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'text.primary',  // color del texto
          backgroundColor: 'background.paper',  // color de fondo
          border: '1px solid #ccc',  // borde
          borderRadius: 2,
          p: 1,
        }}
      >
        <p>Nature:</p>
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectAllNature}
            onChange={handleSelectAllNature}
          />
        }
        label="All"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedNature.includes('Beach')}
            onChange={() => handleMenuItemClick2('Beach')}
          />
        }
        label="Beach"
      />
      <FormControlLabel 
        control={
          <Checkbox 
            checked={selectedNature.includes('Mountain')}
            onChange={() => handleMenuItemClick2('Mountain')}
          />
        }
        label="Mountain"
      />
    </Box>
    
    </div>
  );
let monthArray;
// Función para calcular el score del mes
function calculateMonthScore(selectedMonth, countryMonths) {
  // Primero, convertimos el selectedMonth y countryMonths en números
  selectedMonth = parseInt(selectedMonth);
  const countryMonthsArray = countryMonths.split(',').map(month => parseInt(month.trim()));

  let minDiff = Infinity;

  for (const month of countryMonthsArray) {
    // Calcular diferencia absoluta
    let diff = Math.abs(month - selectedMonth);

    // Considerar el salto de diciembre a enero
    diff = Math.min(diff, 12 - diff);

    if (diff < minDiff) {
      minDiff = diff;
    }
  }

  switch (minDiff) {
    case 0: return 100;
    case 1: return 50;
    case 2: return 25;
    case 3: return 10;
    default: return 0;
  }
}
function getIsoCountryByIataCode(iata) {
  const airport = airportsData.find(airport => airport.iata_code === iata);
  return airport ? airport.iso_country : null;  // Devuelve null si no encuentra el aeropuerto
}
function getCountryNameByIsoCode(isoCode) {
  return Object.keys(countryIcons2).find(country => countryIcons2[country] === isoCode) || null;
}
function countryExistsInToRotate(countryName) {
  return toRotate2.includes(countryName);
}
let qContinent;
let qContinent2;

function formatDate(date) {
  const year = date.getFullYear();
  // Los meses en JavaScript van de 0 a 11, por lo que debes sumar 1.
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

useEffect(() => {
  tripDuration = localStorage.getItem('tripDuration');
  selectedMonths = localStorage.getItem('selectedMonths');
  location = localStorage.getItem('location');
  selectedDate=formatDate(new Date(((dayjs(localStorage.getItem('departureDate') || undefined)))));
  marginDays = localStorage.getItem('marginDays');

  sumarDias(marginDays, tripDuration);

  const location2 = JSON.parse(location);
  if (location !== null) {
    location4=location2.value;
    if(location2.value.length===3){
      const isoCountry = getIsoCountryByIataCode(location2.value);
      const countryName = getCountryNameByIsoCode(isoCountry);
      location3 = countryName;
    }
    else location3 = location2.label;


}

  if (tripDuration !== null && selectedMonths !== null && selectedMonths != null && selectedMonths !== "null" && location !== null && location != null && location !== "null") {
    if (inView) {
      let q;
      const monthArray2 = JSON.parse(selectedMonths);
      

      let monthArray = [];
      if (monthArray2 !== null) monthArray = monthArray2.value;

      // Utilizando Firestore
      const countriesRef = collection(db2, "filterCountry");
     

      tripDuration = tripDuration.replace(/^"|"$/g, '');


      
      if (tripDuration < 7 && location3 && !userManuallySelectedContinent) {
        // Consulta a Firestore para obtener el continente del país de origen
        qContinent = query(countriesRef, where("pais", "==", location3));
        getDocs(qContinent)
          .then((snapshot) => {
            if (!snapshot.empty) {
              const countryData = snapshot.docs[0].data();
              if (!selectedContinent.includes(countryData.continent)) {
                setSelectedContinent(prevContinents => [...prevContinents, countryData.continent]);
              }

              // Ahora usa originContinent en tus consultas posteriores
              // Aquí colocas el resto de tu lógica para construir la consulta y realizarla...
              
            } else {
              console.error("No se encontró el país de origen en la base de datos.");
            }
          })
          .catch((error) => {
            console.error("Error al obtener el continente del país de origen:", error);
          });
      } else if (tripDuration >= 7 && location3 && !userManuallySelectedContinent) {

        // Consulta a Firestore para obtener el continente del país de origen
        qContinent = query(countriesRef, where("pais", "==", location3));
        getDocs(qContinent)
            .then((snapshot) => {
                if (!snapshot.empty) {
                    const countryData = snapshot.docs[0].data();
                    if (selectedContinent.includes(countryData.continent)) {
                        // Filtrar el continente automático fuera de la lista
                        const updatedContinents = selectedContinent.filter(continent => continent !== countryData.continent);
                        setSelectedContinent(updatedContinents);
                    }
                } else {
                    console.error("No se encontró el país de origen en la base de datos.");
                }
            })
            .catch((error) => {
                console.error("Error al obtener el continente del país de origen:", error);
            });
    }
    

      // Construyendo la consulta
      if (selectedContinent && selectedContinent.length > 0 && selectedNature && selectedNature.length > 0) {
        if(selectedNature.includes("Beach")) {

          if(selectedNature.includes("Mountain")) {

            q = query(countriesRef, where("continent", "in", selectedContinent), where("beach", "==", 1), where("mountain", "==", 1));
        } else {q = query(countriesRef, where("continent", "in", selectedContinent), where("beach", "==", 1));
      }

      }else if(selectedNature.includes("Mountain")) {
      q = query(countriesRef, where("continent", "in", selectedContinent), where("mountain", "==", 1));}
    }
      else if (selectedContinent && selectedContinent.length > 0) {
        q = query(countriesRef, where("continent", "in", selectedContinent));
      }
      else if (selectedNature && selectedNature.length > 0) {
        if(selectedNature.includes("Beach")) {

          if(selectedNature.includes("Mountain")) {

            q = query(countriesRef, where("beach", "==", 1), where("mountain", "==", 1));
        } else {q = query(countriesRef, where("beach", "==", 1));
      }

      }else if(selectedNature.includes("Mountain")) {
      q = query(countriesRef, where("mountain", "==", 1));}




      }
      else {
        q = query(countriesRef);
      }

      // Realizando la consulta
      getDocs(q)
  .then((querySnapshot) => {
    const countries = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const preferences = {
        'artCultural': art,
        'aventure': aventure,
        'entertainment': entertainment,
        'historical': historical,
        'nature': nature,
        'nightLife': nightLife,
      };
          
      let score_calculation = 0;
      for (const [column, value] of Object.entries(preferences)) {
        score_calculation += value !== 0.25 ? (value * 10 * (data[column] - value * 100)) : 0;
      }

      // Lógica del mes
      let month_score = calculateMonthScore(monthArray, data.mes);

      data.total_score = 0.5 * score_calculation + month_score*score_calculation; 

      // Agregar el score total a los datos

      if (data.pais !== location3) {
        countries.push(data);
      }
    });

    // Ordenar los países por total_score de manera descendente
    countries.sort((a, b) => b.total_score - a.total_score);
      
    setAllCountries(countries);
    setLoaded(true);
  })
  .catch((error) => {
    console.error(error);
    setLoaded(false); // Establecer loaded en false en caso de error
  });
}
  } else {
    setLoaded(false); // Establecer loaded en false si alguna variable es null
  }
}, [inView, selectedContinent, selectedNature, trigger]);


  const getRemovedCountries = () => {
    const removedCountries = localStorage.getItem('removedCountries');
    return removedCountries ? JSON.parse(removedCountries) : [];

  };
  const recoveryCountries = () => {
    localStorage.removeItem('removedCountries');
    setTrigger(trigger + 1); // Incrementar trigger cada vez que se llama a recoveryCountries
    setHasRemovedCountries(false);
    setOpenDialog(false);


};
const recoverCountry = (countryToRecover) => {
  const removedCountries = getRemovedCountries();
  const updatedRemovedCountries = removedCountries.filter((country) => country.pais !== countryToRecover.pais);
  localStorage.setItem('removedCountries', JSON.stringify(updatedRemovedCountries));
  setTrigger(trigger + 1); 
  setHasRemovedCountries(updatedRemovedCountries.length > 0);
};

const handleRecoveryClick = () => {
  setOpenDialog(true);
};
const handleClick = () => {
  setOpenDialog2(true);
};


useEffect(() => {
  // Comprueba si hay datos en el localStorage al cargar la página.
  const removedCountries = localStorage.getItem('removedCountries');
  if (removedCountries && JSON.parse(removedCountries).length > 0) {
    setHasRemovedCountries(true);
  }
}, []);
const recoveryButtonStyle = hasRemovedCountries ? { color: "white" } : {};

//animate__animated 
const buttonStyle = {
  position: 'absolute',
  marginRight: '10%', // Utiliza porcentajes en lugar de valores fijos
  top: '0', // Ajusta la posición vertical según tus necesidades
  right: '0', // Ajusta la posición horizontal según tus necesidades
};


const sumarDias = (plus, days) => {
  days = days.replace(/^"|"$/g, '');


  if (selectedDate) {
      let nuevaFecha = new Date(selectedDate);
      let nuevaFecha2 = new Date(selectedDate);
      let nuevaFecha3 = new Date(selectedDate);
      let nuevaFecha4 = new Date(selectedDate);

      nuevaFecha.setDate(nuevaFecha.getDate() - parseFloat(plus));
      nuevaFecha2.setDate(nuevaFecha2.getDate() + parseFloat(plus));
      
      nuevaFecha3.setDate(nuevaFecha3.getDate() + (parseFloat(days) - parseFloat(plus)));
      nuevaFecha4.setDate(nuevaFecha4.getDate() + (parseFloat(days) + parseFloat(plus)));

      setSelectedDateBefore(formatDate(nuevaFecha));
      setSelectedDateAfter(formatDate(nuevaFecha2));
      setReturnDateBefore(formatDate(nuevaFecha3));
      setReturnDateAfter(formatDate(nuevaFecha4));


  }
}

  return (
    <section className="project" id="projects" ref={ref} >
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <Button
                            className="top-button yellowBackground buttonFloatSpecial"
                            onClick={handleButtonClick}
                            variant="contained"
                            color="primary"
                            style={buttonStyle} // Aplica el estilo
                          >
                            {buttonLabel}
                          </Button>
                          {buttonLabel === 'Order by Flights' ? (
                    // Contenido a mostrar cuando el botón tiene el valor 'Option 1'
                    <div className="skill-bx">
                                      <h3>Recommended Destinations</h3>
                        
                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                      <Tab.Content id="slideInUp" className={isVisible ? "animate__slideInUp" : ""}>
                        <Tab.Pane eventKey="first">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <FilterButton onClick={handleRecoveryClick} style={recoveryButtonStyle}>
                            <DeleteSweepIcon style={{ marginRight: '5px' }} />
                            <span>Recovery</span>
                          </FilterButton>
                         
                          <FilterButton2 selectedContinent={selectedContinent} selectedNature={selectedNature} onClick={handleClick}>
                            <FilterListRounded style={{ marginRight: '5px' }} />
                            <span>Filters</span>
                          </FilterButton2>
                            <Dialog open={openDialog2} onClose={() => setOpenDialog2(false)}>
                              <DialogTitle id="alert-dialog-title">{"Filters"}</DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                {Filters()}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                  <Button onClick={handleAccept} color="primary" autoFocus>
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog> 
                          </div>
                          <div style={{ minHeight: '100px' }}>
                            {(tripDuration === null || monthArray === null) ? (
                              <p>loading...</p>
                            ) : loaded ? (
                              <>
                                                  <p>These are the recommended countries for your next travel. If you want to see more details of any country click on it. Enjoy!</p>

                                <Row key="pais">
                                  {pais.map((pais, budget) => (
                                    <Col size={12} sm={6} md={4} key={budget + pais}>
                                      <ProjectCardMini {...pais} onRemove={() => removeCountry(pais)} />
                                  </Col>
                                  ))}
                                </Row>
                                <Row key="pais2">
                                  {pais2.map((pais, budget) => (
                                    <Col size={12} sm={6} md={3} key={budget+pais}>
                                      <ProjectCardMini {...pais} onRemove={() => removeCountry(pais)} />
                                  </Col>
                                  
                                  ))}
                                </Row>
                              </>
                            ) : (
                              <p>please fill out all fields</p>
                            )}
                        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                          <DialogTitle id="alert-dialog-title">{"Attention"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              You have removed the following countries:
                              <ul>
                                {getRemovedCountries().map((country) => (
                                  <li key={country.pais}>         {country.pais}

                                  <IconButton onClick={() => recoverCountry(country)}>
                                  
                                  <UndoIcon />
                                </IconButton>
                                  </li>
                                ))}
                              </ul>
                              You are able to recover them whenever you want.
                            </DialogContentText>
                            <FormControlLabel
                              control={<Checkbox checked={dontShowAgain} onChange={() => setDontShowAgain(!dontShowAgain)} />}
                              label="Do not show this message again when delete a country"
                            />
                          </DialogContent>
                          <DialogActions>
                          <Button onClick={recoveryCountries} color="primary" autoFocus>
                              Recovery all
                            </Button>
                            <Button onClick={handleAccept} color="primary" autoFocus>
                              Accept
                            </Button>
                          </DialogActions>
                        </Dialog>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                    // Agrega aquí el resto de tu contenido específico
                  ) : buttonLabel === 'Order by Likes' ? (
                    // Contenido a mostrar cuando el botón tiene el valor 'Option 2'
                    <div className="skill-bx">
                     <h3>Destinations</h3>
                     {pais && (
                      <>
                      <WidgetComponent3
                        from={location4}
                        to={pais.map((country) => {
                          switch (country.continent) {
                            case 'Europa':
                              return 'europe';
                            case 'America':
                              return 'central-america,north-america,south-america';
                            case 'Asia':
                              return 'asia';
                            case 'Africa':
                              return 'africa';
                            case 'Oceania':
                              return 'oceania';
                            default:
                              return '';
                          }
                        }).filter((continent) => continent !== '').join(',')}
                        departure={selectedDateBefore + "_" + selectedDateAfter}
                        containerId='1'
                        affilId='jomajolajomajolawidget'
                        vuelta={returnDateBefore + "_" + returnDateAfter}
                      />
                      </>
                    )}                    
                    </div>
                    // Agrega aquí el resto de tu contenido específico
                  ) : null}
                 
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
  
  };
  const FilterButton2 = styled('div')(({ theme, selectedContinent, selectedNature }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',
    '&:hover': {
      color: '#3f51b5',
    },
    '&:after': {  // Usamos ':after' en lugar de ':before'
      content: selectedContinent.length > 0 || selectedNature.length > 0 ? '""' : 'none', // Nota que el contenido es una cadena vacía
      position: 'absolute', // Absoluto en relación al padre (FilterButton2)
      marginLeft: '18px',
      marginBottom: '12px',
      width: '5px',  // Tamaño del círculo
      height: '5px',
      borderRadius: '50%',  // Esto hace que sea un círculo
      backgroundColor: 'red',  // Color del círculo, cámbialo según tu diseño
    }
  }));
 