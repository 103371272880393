import React, { useState, useEffect } from 'react';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === 'true') {
      setShowBanner(false);
    }
  }, []);

  return (
    showBanner && (
      <div className="cookie-banner" style={styles.cookieBanner}>
        <p style={styles.text}>
          This website uses cookies to improve the user experience. By continuing
          browsing, you accept the use of cookies in accordance with our{' '}
          <a href="/Privacy-Policy" target="_blank">Privacy Policy</a>.
        </p>
        <div style={styles.buttonContainer}>
          <button onClick={handleAcceptCookies} style={styles.acceptButton}>
            Accept
          </button>
          <button onClick={handleAcceptCookies} style={styles.reuseButton}>
            Decline optional cookies
          </button>
        </div>
      </div>
    )
  );
};

const styles = {
  cookieBanner: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    backgroundColor: '#f0f0f0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column', // Stack elements vertically
    alignItems: 'center', // Center the elements horizontally
    padding: '10px',
    fontSize: '8px',
    zIndex: 9999, // Set a high z-index value to keep it above everything
  },
  text: {
    margin: '0',
    padding: '0',
    color: '#000',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '10px', // Add some spacing between text and buttons
  },
  acceptButton: {
    background: '#007bff',
    border: 'none',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  reuseButton: {
    background: '#646464',
    border: 'none',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
};

export default CookieConsentBanner;
