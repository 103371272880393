import React, { useEffect, useRef } from 'react';

const WidgetComponent3 = ({ from, to, departure, containerId, affilId, vuelta}) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-passengers', '1-0-0');
    script.setAttribute('data-lang', 'en');
    script.setAttribute('data-affilid', affilId);
    script.setAttribute('data-from', from);
    script.setAttribute('data-to', to);
    script.setAttribute('data-departure', departure);
    script.setAttribute('data-return', vuelta);
    script.setAttribute('data-return-from-different-airport', true);
    script.setAttribute('data-return-to-different-airport', true);
    script.setAttribute('data-cabin', 'ECONOMY');
    script.setAttribute('data-transport-types', 'FLIGHT,BUS,TRAIN');
    script.setAttribute('data-stop-number', '1');
    script.setAttribute('data-container-id', containerId);
    script.setAttribute('data-iframe-id', `widget-iframe-${containerId}`);
    script.setAttribute('data-results-only', true);
    script.src = 'https://widgets.kiwi.com/scripts/widget-search-iframe.js';

    const container = document.getElementById(containerId);
    container.appendChild(script);

    // Store the script element in the ref for later cleanup
    scriptRef.current = script;

    // Cleanup function to remove the script element
    return () => {
      if (scriptRef.current) {
        container.removeChild(scriptRef.current);
      }
    };
  }, [from, to, departure, containerId, affilId]);

  return <div id={containerId}></div>;
};

export default WidgetComponent3;
