import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Spinner from 'react-bootstrap/Spinner';
import 'firebase/firestore';
import RestaurantIcon from '@mui/icons-material/Restaurant'; // Import the icon you want to use
import LandscapeIcon from '@mui/icons-material/Landscape';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const ProjectCardImages = ({ pais, category, visible, choose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [displayedImage, setDisplayedImage] = useState(null);

    // Codificamos el nombre de la categoría antes de usarlo en la ruta
    let localImagePath;

    // Aquí definimos la estructura de la ruta a las imágenes locales
    if (category === "vegetarian and vegan") {
        localImagePath = `${process.env.PUBLIC_URL}/${category}`;
    } else {

        localImagePath = `${process.env.PUBLIC_URL}/${pais}/${pais}_${category}`;
    }

    // Precargamos la imagen
    useEffect(() => {
        if (visible) {
            setIsLoading(true);
            const img = new Image();
            img.src = `${localImagePath}.webp`;
            img.onload = () => {
                setIsLoading(false);
                setDisplayedImage(`${localImagePath}.webp`);
            };
            img.onerror = () => {
                setIsLoading(false);
                setDisplayedImage(`${localImagePath}.jpg`);
            };
        }
    }, [localImagePath, visible]);

    return (
        <Carousel className="center-icon" controls={false} prevIcon="" nextIcon="" indicators={false}>
            <Carousel.Item>
                <div className="" style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                        <Spinner
                        animation="border"
                        role="status"
                        style={{ animationDuration: '2s' }}
                    >
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    ) : (
                        <>
                       <div className="right-icons-container" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
                            {choose === 'food' && <RestaurantIcon fontSize="large" style={{ color: 'white' }} />}
                            {choose === 'nature' && <LandscapeIcon fontSize="large" style={{ color: 'white' }} />}
                            {choose === 'interest' && <AccountBalanceIcon fontSize="large" style={{ color: 'white' }} />}
                        </div>               
               <img src={displayedImage} style={{ width: '100%', height: '300px', objectFit: 'cover' }} alt={pais} />

                        
                        </>
                    )}
                </div>
            </Carousel.Item>
        </Carousel>
    );
};
