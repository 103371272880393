import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAVS3Zps8jX9_2J-VVbg3GIfBysN9Z4gLs",
  authDomain: "jomajola2.firebaseapp.com",
  projectId: "jomajola2",
  storageBucket: "jomajola2.appspot.com",
  messagingSenderId: "815910229538",
  appId: "1:815910229538:web:b0025c64ef5658915bdc7b",
  measurementId: "G-B5X1T5E3YX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
export const db2 = getFirestore(app);
