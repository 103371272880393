import 'react-multi-carousel/lib/styles.css';
import { Spring, config } from "react-spring/renderprops.cjs";
import React, { useState } from 'react';

import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  useCircularInputContext
} from "react-circular-input";
import { Bank2, Easel2Fill, Flower3, MoonFill, Tsunami, Tv } from 'react-bootstrap-icons';


export const InputCircle = ({ nombre }) => {
  var iconPath;
  if (nombre==="Aventure") {
    console.log(nombre)
    iconPath =<Tsunami x={50} y={50} size={50}  />;
  } else if (nombre==="Nature"){
    iconPath =<Flower3 x={50} y={50} size={50} />;
  } else if (nombre==="Historical"){
    iconPath =<Bank2 x={50} y={50} size={50} />;
  } else if (nombre==="Entertainment"){
    iconPath =<Tv x={50} y={50} size={50} />;
  }else if (nombre==="Art"){
    iconPath =<Easel2Fill x={50} y={50} size={50} />;
  }
  else if (nombre==="Nightlife"){
    iconPath =<MoonFill x={50} y={50} size={50} />;
  }
  
    const [value, setValue] = useState(
      parseFloat(localStorage.getItem(nombre)) || 0.50 // Obtener el valor guardado en localStorage o utilizar 0.25 como valor inicial
    );
  
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
  
    function CustomComponent() {
      const { getPointFromValue, value } = useCircularInputContext();
      const point = getPointFromValue();
      if (!point) return null;
      return (
        <text
          {...point}
          textAnchor="middle"
          dy="0.35em"
          fill="rgb(61, 153, 255)"
          style={{ pointerEvents: "none", fontWeight: "bold" }}
        >
          {Math.round(value * 100)}
        </text>
      );
    }
    
  
    const handleValueChange = newValue => {
      setValue(newValue);
      localStorage.setItem(nombre, newValue); // Guardar el nuevo valor en localStorage
    };
  
    //            <CustomComponent />

    return (
      <Spring to={{ value }} config={config.stiff}>
        {props => (
          
          <CircularInput key={nombre}
          value={props.value}
          radius={75}
          onChange={handleValueChange} >
	<CircularTrack strokeWidth={2} stroke="#eee" />
  
	<CircularProgress stroke={`hsl(${props.value * 100}, 50%, 50%)`}  />

  {iconPath}


</CircularInput>

         
        )}
      </Spring>
    );
  };
  

