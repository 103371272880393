import React, { useState, useEffect } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import 'animate.css';
import CookieConsentBanner from './CookieConsentBanner';
import logo from "../assets/img/footer.png";
import { toRotate2 } from '../data/countryData';  // Asegúrate de que la ruta sea correcta
import { Flights } from './Flights';
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { Footer } from "./Footer";


export const Banner = () => {

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');

  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [showAdditionalComponent, setShowAdditionalComponent] = useState(false);

  const toRotate = toRotate2;
  /*useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
        document.body.style.overflow = 'auto';
    };
}, []);*/

  //Variable de selección de mes y dias.

  const period = 2000;
  const handleVisibilityChange = (isVisible) => {
    setShowAdditionalComponent(isVisible);
  };

 
  
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])




  

  const tick = () => {
    //Es el metodo que permite lo de las letras, que aparezca y desaparezca y cambie el pais.
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  
  
return (
  <>
  <section className="banner" id="home" style={{ height: '100vh' }}>
     <div style={{
        position: 'absolute',  // Posiciona el logo de manera fija
        top: '20px',        // A 20px del borde superior
        right: '20px',      // A 20px del borde derecho
        zIndex: 1000        // Asegúrate de que el logo esté sobre otros elementos
      }}>
        <img src={logo} alt="JomaJola" className="logo-fixed" />
      </div>

      <CookieConsentBanner /> {/* Agregar el banner emergente de cookies */}
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={6} xl={7}>
              <div className="animate__animated animate__fadeIn" style={{ }}>
                <h1>{`Your next adventure will be in`} <br></br>
                  <span className="txt-rotate" data-period="1000" data-rotate={toRotate}>
                    <span className="wrap">{text}</span></span> </h1>

                
                </div>

        </Col>
        <Flights onVisibilityChange={handleVisibilityChange}></Flights>

       <div className='mt-0' >
                </div>



      </Row>
      
    </Container>
    
  </section>{showAdditionalComponent && <>
  
        <Skills />
        <Projects />

        <Footer />
        </>
      }

        </>
)
}
