import { useState, useEffect } from 'react';
import 'firebase/firestore';
import { query, where, getDocs, collection } from "firebase/firestore";
import { db2 } from './firebase';
import { countryIcons2 } from '../data/countryData';

export const fetchStopoverReturn = async ({ pais, location }) => {
  const countriesRef = collection(db2, "stopOver");
  const q = query(countriesRef, where("pais", "==", pais));

  const querySnapshot = await getDocs(q);
  const countries = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.stopover !== location) {
      countries.push(data);
    }
  });

  return countries;
};
