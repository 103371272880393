import React, { useState, useEffect } from 'react';
import 'firebase/firestore';
import { query, where, getDocs, collection } from "firebase/firestore";
import { db2 } from './firebase';


export const ItineraryList = ({ pais }) => {
  
  const [posts, setPosts] = useState([]);


  useEffect(() => {
    handleContinue();
  }, []);

  const handleContinue = async () => {
    let q;
    const countriesRef = collection(db2, "mapsItinerary");
    q = query(countriesRef, where("pais", "==", pais));
    getDocs(q)
    .then((querySnapshot) => {
      const countries = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        countries.push(data);
        
      });
      setPosts(countries);
    })
      .catch(error => {
        console.error(error);
      });
  };
  /*
        <section className="budget" id="itinerary">
        <div className="col-12">
          <div className="itinerary-bx">
            <h3 id="Itineraryh3">Map</h3>
            */

  return (
    <>

            {posts.length > 0 ? (
              posts.map((paisData, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: paisData.iframe }}
                />
              ))
            ) : (
              <p>Loading...</p>
            )}
    </>
  );
};
