import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CountryDetail from './components/CountryDetail';


//      <NavBar />       <Contact />

/*
<div className="App">
      <CountryDetail />
      <div className='placeCenter'>
      <Budget/>
      <Budget/>
      </div>
      <Skills />
      <Projects />
      <Footer />
    </div>
    */

function Country() {
  return (
<div className="App">
    <CountryDetail></CountryDetail>
    </div>

  );
}

export default Country;
