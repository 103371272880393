import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db2 } from './firebase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ForumIcon from '@mui/icons-material/Forum';
import GavelIcon from '@mui/icons-material/Gavel';
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

export const Tips = ({ pais }) => {
  const [posts, setPosts] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    handleContinue();
  }, []);

  const handleContinue = async () => {
    try {
      const countriesRef = collection(db2, 'tips2');
      const q = query(countriesRef, where('country', '==', pais));
      const querySnapshot = await getDocs(q);
      const countries = querySnapshot.docs.map((doc) => doc.data());
      setPosts(countries);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const createStopoverItem = (icon, title) => (
    <Col key={title} className="mb-3">
      <div className="stopover-item">
        {React.cloneElement(icon, { color: '#E2B60A', fontSize: 'large' })}
        <div className="prueba mt-2">{title}</div>
      </div>
    </Col>
  );
  const formatLawText = (text) => {
    // Regex para identificar números seguidos por un punto
    const regex = /(\d+\.\s)/g;
    return text.replace(regex, '<br>$1');
  }
  const formatText = (text) => {
    // Regex para identificar puntos simples.
    const regex = /(\.)/g;
    return text.replace(regex, '.<br>');
  }
/* <h3>Tips
            <button onClick={handleToggleMinimize} style={{ fontSize: '2rem', padding: '1rem' }}>
              {isMinimized ? <ArrowDropDownIcon fontSize="inherit" /> : <ArrowDropUpIcon fontSize="inherit" />}
            </button>
          </h3>*/
          const getLanguagesDisplay = (oficialLangs, speakLangs) => {
            const officialArray = oficialLangs.split(',').map(lang => lang.trim());
            const speakArray = speakLangs.split(',').map(lang => lang.trim());
        
            if (officialArray.length >= 4) {
              return {
                langsToShow: officialArray.slice(0, 4).join(', '),
                showIcon: false
              };
            } else {
              const remainingSlots = 4 - officialArray.length;
              return {
                langsToShow: officialArray.concat(speakArray.slice(0, remainingSlots)).join(', '),
                showIcon: true
              };
            }
          };
          
  return (
    <section className="budget border" id="tips">
        <div className="budget-bx ">
        {!isMinimized && (
        <>
          {posts.length > 0 && posts.map((transport, index) => {
            const { langsToShow, showIcon } = getLanguagesDisplay(transport.oficiallang, transport.speaklang);
            return (
              <Row key={index}>
                {createStopoverItem(<CurrencyExchangeIcon style={{color: '#E2B60A'}} />, `${transport.currency}`)}
                {createStopoverItem(<CreditCardIcon style={{color: '#E2B60A'}}/>,  `${transport.card}`)}
                <Col key={transport.oficiallang} className="mb-3">
                  <div className="stopover-item">
                    <ForumIcon style={{ color: '#E2B60A' }} />
                    <div className="prueba mt-2">
                      {langsToShow}
                      {showIcon && <VerifiedSharpIcon fontSize='small' />}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </>
      )}
        </div>
    </section>
  );
};
