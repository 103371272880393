import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import { UilEstate, UilRestaurant, UilMetro } from '@iconscout/react-unicons'
import { Ticket } from 'react-bootstrap-icons';
import 'firebase/firestore';
import { query, where, getDocs, collection, and } from "firebase/firestore";
import { db2 } from './firebase';
import { Tips } from './Tips';

export const Budget = ({pais}) => {
  const [budget, setBudget] = useState([]);


useEffect(() => {
  
  fetchEvents();
}, []);

const fetchEvents = async () => {
  
  let q;
  const budgetRef = collection(db2, "budget");
  q = query(budgetRef, where("pais", "==", pais));
  getDocs(q)
    .then((querySnapshot) => {
      const countries = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        countries.push(data);
        
      });
      setBudget(countries);
    })
      .catch(error => {
        console.error(error);
      });
      
  };
//<p>This is an approximate scale of the daily expense that you will have on vacation, it all depends on your travel style.</p>


    return (
      <section className="budget border" id="budget">
                <div className="col-12 " >
                    <div className="budget-bx ">

                        <Row>
                              <Col size={1} md={3} sm={2} key={budget[0]?.hotel} className='placeCenter2'>
                                <UilEstate style={{color: '#E2B60A'}} className='placeCenter4' size={35} />                                 
                                <p>{budget[0]?.hotel} €</p>
                                 </Col>
                                 <Col size={1} md={3} sm={2} key={budget[0]?.food} className='placeCenter2'>
                                <UilRestaurant style={{color: '#E2B60A'}} className='placeCenter4' size={35} />                                 
                                <p>{budget[0]?.food} €</p>
                                 </Col>
                                 <Col size={1} md={3} sm={2} key={budget[0]?.transport} className='placeCenter2'>
                                <UilMetro style={{color: '#E2B60A'}} className='placeCenter4' size={35} />                                 
                                <p>{budget[0]?.transport} €</p>
                                 </Col>
                                 <Col size={1} md={3} sm={2} key={budget[0]?.museum} className='placeCenter2'>
                                <Ticket style={{color: '#E2B60A'}} className='placeCenter4' size={35} />                                 
                                <p>{budget[0]?.museum} €</p>
                                 </Col>
                          </Row>
                          <p className='border2'><strong>{budget[0]?.total} €/day</strong></p>
                      </div>
                      <Tips pais={pais}></Tips>
                      
                      
            </div>
      </section>
          )
    
       
  }

