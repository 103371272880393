import React,  { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const ProjectCardMini = ({ pais, budget, onRemove}) => {
  const [resetKey, setResetKey] = useState(Date.now());


  //Variable Carousel
  const [index, setIndex] = useState(0);
  //Variable para almacenar la imagen y decidir cual mostrar
  const [posts, setPosts] = useState([]);
  const [image, setImage] = useState();
  const [previousPais, setPreviousPais] = useState(pais);


  //Metodo del carrousel que selecciona el indice.
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

//Parametro de la llamada API, habría que quitarla de aqui y hacerla variable externa.
const config = {
  headers:{
    Authorization: process.env.REACT_APP_TOKEN_PEXELS,
  }
};
//URL llamada API
const url = "https://api.pexels.com/v1/search?query="+ pais + "&orientation=landscape" +"&per_page=6";

//Carga inicial de la página, se ejecuta con cada cambio de posts o index. 
useEffect(() => {
  if (pais !== previousPais) {
    setPreviousPais(pais);
    setIndex(0);
    handleContinue();
  } else if (posts.length === 0) {
    handleContinue();
  } else {
    setImage(posts[index].src.landscape);
  }
}, [posts, index, pais]);
//Metodo que lo junta todo y hace la llamada API
  const handleContinue = async () => {
    await axios.get(url, config)
      .then(response => {        
        setPosts(response.data.photos);
        setResetKey(Date.now());      })
      .catch(error => {
        console.error(error);
      });
  };
  const handleLinkClick = () => {
                      // Resetear la variable de estado aquí
                      localStorage.setItem('destination', null);
                      localStorage.setItem('selecteds', null);
                    }

  return (
    <Carousel key={pais} activeIndex={index} onSelect={handleSelect} interval={null} className="center-icon">
          {posts.map((post, i) => (
            i === 0
            ? (
              <Carousel.Item key={i}>
                <div className="head-text proj-imgbx">
                  {/* Aquí puedes hacer algo diferente para la imagen inicial */}
                  <Link
    to={`/CountrySelected/${pais}`}
    onClick={handleLinkClick}  // Aquí llamamos a la función
    target="_blank"
    rel="noreferrer"
    style={{ textDecoration: 'none', color: 'inherit' }}
>
    <img src={post.src.landscape} id={post.src.landscape} alt={pais} />

    <div className="proj-txtx">
        <div className="weather2">
            <h6>{budget}€/day</h6>
        </div>
        <h4 className="text-on-image">{pais}</h4>
    </div>
</Link>

                    <div className="weather h4">
                      <button onClick={(e) => { e.stopPropagation(); onRemove(pais);}}>X</button>
                    </div>
                  </div>
                      </Carousel.Item>
            )
            : (
              <Carousel.Item key={i}>
                <div className="head-text proj-imgbx2">
                  {/* Este es el contenido normal para los otros elementos del Carousel */}
                  <Link to={`/CountrySelected/${pais}`} target="_blank" rel="noreferrer">
                    <img src={post.src.landscape} id={post.src.landscape}></img>
                  </Link>
                </div>
              </Carousel.Item>
            )
          ))}
    </Carousel>
  )
  
  
}
