import React, { useState, useEffect } from 'react';
import 'flag-icon-css/css/flag-icons.min.css';
import 'firebase/firestore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import WidgetComponent3 from './WidgetComponent3';
import FlightInfo from './FlightInfo';
import Select from 'react-select'
import { fetchStopoverReturn } from './fetchStopoverReturn';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { countryIcons2, toRotate2 } from '../data/countryData';
import airportsData from '../data/airports';
import Checkbox from '@mui/material/Checkbox';

export const Flights = ({ onVisibilityChange }) => {

  /*
  6. Pensar como poner los stopovers -- Mañana
  */
  const countryIcons = countryIcons2;
  const toRotate = toRotate2;

  const [marginDays, setMarginDays] = useState(localStorage.getItem('marginDays') || 0);
  const [selectedDateBefore, setSelectedDateBefore] = useState(new Date());
  const [selectedDateAfter, setSelectedDateAfter] = useState(new Date());
  const [returnDateBefore, setReturnDateBefore] = useState(new Date());
  const [returnDateAfter, setReturnDateAfter] = useState(new Date());
  const [stopoverChecked, setStopoverChecked] = useState(false); 
  const [tripDuration, setTripDuration] = useState(JSON.parse(localStorage.getItem('tripDuration')) || '');
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem('departureDate') ? dayjs(localStorage.getItem('departureDate')) : null);
  const [isClearable2, setIsClearable2] = useState(true);
  const [selected3, setSelected3] = useState(JSON.parse(localStorage.getItem('selecteds')) || []); //FALTAS TU
  const [optionsLocation3, setOptionsLocation3] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(JSON.parse(localStorage.getItem('destination'))|| '');
  const [selectedLocation2, setSelectedLocation2] = useState(JSON.parse(localStorage.getItem('location')) || '');
  const [optionsLocation, setOptionsLocation] = useState([]);
  const [optionsLocation2, setOptionsLocation2] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [tripOption, setTripOption] = useState(localStorage.getItem('tripOption') || 'RoundTrip'); 
  const [selectedButton, setSelectedButton] = useState(localStorage.getItem('selectedButton') || 'left');
  const [transformedCountries2, setTransformedCountries2] = useState([]);
  const [postsX, setPostsX] = useState([]);



  const handleCheckboxChange = (event) => {


    
    setSelectedButton(event.target.checked ? 'center' : 'left');
    if(selectedButton==='center'){
      setStopoverChecked(false);
      setTripOption('RoundTrip');


    }
  };
   const getCountryName = (country) => {
    if (countryIcons[country]) {
      return countryIcons[country];
    }
    
    return country; // Si no se encuentra, simplemente retorna el nombre del país tal como se ha dado
  }
  // Esta función se llamará cada vez que cambie el valor de selected o tripDuration
  useEffect(() => {
    onVisibilityChange(false);

    // Guardamos los valores en el localStorage
    if ((tripDuration!=null && tripDuration!= undefined && tripDuration!="") || (marginDays!=null && marginDays!= undefined && marginDays!="") || (selectedLocation!= null && selectedLocation!= undefined && selectedLocation!="") || (selectedLocation2!= null && selectedLocation2!= undefined && selectedLocation2!="") || (selectedDate!= null && selectedDate!= undefined && selectedDate!="")){
    
      //console.log("seteo" + selected + " . " + tripDuration);
    //localStorage.setItem('selectedMonths', JSON.stringify(selected));
    localStorage.setItem('tripDuration', JSON.stringify(tripDuration));
    localStorage.setItem('location', JSON.stringify(selectedLocation2));
    localStorage.setItem('destination', JSON.stringify(selectedLocation));
    localStorage.setItem('departureDate', selectedDate);
    localStorage.setItem('marginDays', marginDays);
    localStorage.setItem('selectedButton', selectedButton);
    localStorage.setItem('tripOption', tripOption);

    console.log(selected3);
    localStorage.setItem('selecteds', JSON.stringify(selected3));


    

    let newDepartureDate = new Date(selectedDate);

    if(newDepartureDate!=null && newDepartureDate!=undefined){
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthNumber = newDepartureDate.getMonth() + 1; // getMonth() devuelve valores de 0 (enero) a 11 (diciembre), así que sumamos 1.
    const monthName = monthNames[newDepartureDate.getMonth()];
    const monthData = {
      label: monthName,
      value: monthNumber < 10 ? `0${monthNumber}` : monthNumber.toString()  // Asegura que el valor sea de dos dígitos
    };
    localStorage.setItem('selectedMonths', JSON.stringify(monthData));
    //alert(dayNumber);
    //localStorage.setItem('departureDate', dayjs(new Date(yearNumber, monthNumber-1, dayNumber)));

    
  }
  
  }
  }, [tripDuration, selectedLocation, selectedLocation2, selectedDate, marginDays, selectedButton, tripOption, selected3]);
  


  useEffect(() => {
    // Función anónima autoinvocada para manejar la asincronicidad
    (async () => {
      // Solo procede si ambos selectedLocation.label y selectedLocation2.label tienen valores válidos

      if (selectedLocation!= undefined && selectedLocation!= null && selectedLocation &&  selectedLocation2 && selectedLocation2!= null && selectedLocation2!=undefined) {

        let posts;

        if (selectedLocation.value.length===3){
          const isoCountry2 = getIsoCountryByIataCode(selectedLocation.value);
          const countryName2 = getCountryNameByIsoCode(isoCountry2);
          posts = await fetchStopoverReturn({ pais: countryName2, location: selectedLocation2.label });

        }
        else posts = await fetchStopoverReturn({ pais: selectedLocation.label, location: selectedLocation2.label });

        setPostsX(posts);

        const picklistOptions = posts.map(item => item.stopover);
        picklistOptions.push('Others');
        picklistOptions.unshift('Recommended');
        toRotate.forEach(country => {
          if (!picklistOptions.includes(country)) {
              picklistOptions.push(country);
          }
        });
          const filterOptions = optionsLocation3.filter(option => option.label);
          const transformedPicklistOptions = picklistOptions.map(country => ({
            label: country,
            value: getCountryName(country)  // Esta función debería retornar el código del país o el nombre del país si no encuentra un código
          }));
          const combinedOptions = [...transformedPicklistOptions, ...filterOptions];
          const transformedCountries2 = combinedOptions.map(option => ({
            label: option.label,
            value: option.value
          }));  
        // Actualizar el estado con el nuevo valor
        setTransformedCountries2(transformedCountries2);
  
      }
    })();
  }, [selectedLocation, selectedLocation2, optionsLocation3, toRotate]);
  function getIsoCountryByIataCode(iata) {
    const airport = airportsData.find(airport => airport.iata_code === iata);
    return airport ? airport.iso_country : null;  // Devuelve null si no encuentra el aeropuerto
  }
  function getCountryNameByIsoCode(isoCode) {
    return Object.keys(countryIcons2).find(country => countryIcons2[country] === isoCode) || null;
  }
  function countryExistsInToRotate(countryName) {
    return toRotate2.includes(countryName);
  }
const handleNext = () => {
  // Tu lógica aquí.

  // Validar que se hayan seleccionado los tres campos antes de continuar
  if (((tripOption === 'RoundTrip' || tripOption === 'Multicity') && selectedButton === 'center' && ((tripDuration === "" ||  tripDuration === null || tripDuration === undefined) || (marginDays === "" || marginDays === null || marginDays === undefined)|| selectedLocation2 === null || selectedLocation2 === undefined || selectedLocation2 === "" || selectedLocation === null || selectedLocation === undefined || selectedLocation === "" || !selectedDate.isValid()))) {
    setErrorMessage("Please fill all fields.");
  } 
  else if ((selectedButton === 'left' && ((tripDuration === "" ||  tripDuration === null || tripDuration === undefined) || (marginDays === "" || marginDays === null || marginDays === undefined) || selectedLocation2 === null || selectedLocation2 === undefined || selectedLocation2 === "" || !selectedDate.isValid()))) {

    setErrorMessage("Please fill all fields.");

  }
  else if (tripOption === 'Multicity' && (selected3 === null || selected3 === undefined || selected3 == "" || !selectedDate.isValid())) {
    setErrorMessage("Please fill all fields.");
  }
  else if ((tripOption === 'OneWay' && (marginDays === "" || selectedLocation2 === null || selectedLocation2 === undefined || selectedLocation2 === "" || selectedLocation === null || selectedLocation === undefined || selectedLocation === "" || !selectedDate.isValid()))) {
    setErrorMessage("Please fill all fields.");
  } 
  else {


    // Lógica para continuar con el proceso, por ejemplo, redireccionar a otra página.
    setErrorMessage(""); // Reiniciar el mensaje de error si todo está bien.

    sumarDias(marginDays, tripDuration);
    if (selectedButton==="left") {
      //METER AQUI DELETE de la variable TO.
      localStorage.setItem('destination', null);
      localStorage.setItem('selecteds', null);

      onVisibilityChange(true);
      window.location.replace("/#likes");


    } else {
      if(selectedLocation.value.length>2){
        const isoCountry = getIsoCountryByIataCode(selectedLocation.value);
        const countryName = getCountryNameByIsoCode(isoCountry);
        if (countryExistsInToRotate(countryName)) {

        var url = `/CountrySelected/${countryName}#flights2`;
        window.open(url, '_self'); 
        }
        
      } else {
        if (countryExistsInToRotate(selectedLocation.label)) {
        var url = `/CountrySelected/${selectedLocation.label}#flights2`;
        window.open(url, '_self'); 
        }
        }
        
      

      setStopoverChecked(true);
    }
 
  }
};
  
 
useEffect(() => {
  onVisibilityChange(false);
}, [selectedButton]);

    
const getCountryNameDestination = (country) => {
  if (countryIcons[country]) {
    return countryIcons[country];
  }
  return country; // Si no se encuentra, simplemente retorna el nombre del país tal como se ha dado
}

useEffect(() => {
  fetchAllCities();
}, []);

const fetchAllCities = () => {
  try {
      const filteredAirports = airportsData;

      const uniqueIataCodes = new Set();
      const transformedAirportOptions = [];
      for (let country in countryIcons2) {
        transformedAirportOptions.push({
            label: country,
            value: countryIcons2[country]
        });
    }

      // Procesar los aeropuertos
      filteredAirports.forEach(airport => {
          if (!uniqueIataCodes.has(airport.iata_code)) {
              uniqueIataCodes.add(airport.iata_code);
              transformedAirportOptions.push({
                label: airport.name + " - " + airport.iata_code + " - " +  airport.municipality,
                value: airport.iata_code
              });
          }
      });

      // Añadir los valores de countryIcons2 a transformedAirportOptions

      setOptionsLocation(transformedAirportOptions);
      setOptionsLocation2(transformedAirportOptions);
  } catch (error) {
      console.error("Error processing airports:", error);
  }
};


  const theme = createTheme({
    palette: {
      neutral: {
        main: '#2290ac',
        contrastText: '#fff',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#E2B60A',  // Cambia 'red' por el color que desees para el ícono
        },
      },
    },
  });
  
  function customFilterOption(option, input) {
    // Si el usuario no ha escrito nada, solo muestra las opciones entre "Recommended" y "Others"
    if (!input) {
      const startIndex = transformedCountries2.findIndex(opt => opt.label === "Recommended");
      const endIndex = transformedCountries2.findIndex(opt => opt.label === "Others");
      const currentIndex = transformedCountries2.findIndex(opt => opt.label === option.label);
      return currentIndex >= startIndex && currentIndex < endIndex;
    }
    // De lo contrario, usa el filtrado predeterminado
    return option.label.toLowerCase().includes(input.toLowerCase());
  }
  const sumarDias = (plus, days) => {

    if (selectedDate) {
        let nuevaFecha = new Date(selectedDate);
        let nuevaFecha2 = new Date(selectedDate);
        let nuevaFecha3 = new Date(selectedDate);
        let nuevaFecha4 = new Date(selectedDate);

        nuevaFecha.setDate(nuevaFecha.getDate() - parseFloat(plus));
        nuevaFecha2.setDate(nuevaFecha2.getDate() + parseFloat(plus));
        
        nuevaFecha3.setDate(nuevaFecha3.getDate() + (parseFloat(days) - parseFloat(plus)));
        nuevaFecha4.setDate(nuevaFecha4.getDate() + (parseFloat(days) + parseFloat(plus)));




        setSelectedDateBefore(formatDate(nuevaFecha));
        setSelectedDateAfter(formatDate(nuevaFecha2));
        setReturnDateBefore(formatDate(nuevaFecha3));
        setReturnDateAfter(formatDate(nuevaFecha4));


    }
}

function formatDate(date) {
  const year = date.getFullYear();
  // Los meses en JavaScript van de 0 a 11, por lo que debes sumar 1.
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}
  useEffect(() => {
    //fetchEvents();
    fetchAllCitiesWithAirports(); // Añade esta línea aquí

  }, []);

  


  const handleNext3 = (newValue) => {
    // Tu lógica aquí.
    setSelected3(newValue);

    const selectedCountryCodes = newValue
    .map(option => option.value)
    .filter(Boolean);  // Esto elimina cualquier valor nulo o undefined de la lista
    setStopoverChecked(false);
  };
  const handleNextDeparture = (newValue) => {
    // Tu lógica aquí.
    setSelectedDate(newValue);
    setStopoverChecked(false);
  };
  const handleNextMargin = (newValue) => {
    // Tu lógica aquí.
    setMarginDays(newValue)
    setStopoverChecked(false);
  };

  const handleTripDurationChange = (newValue) => {

    // Validar que el valor ingresado sea un número
    if (!isNaN(newValue)&&newValue.length<4) {
      setTripDuration(newValue);
      localStorage.setItem('tripDuration', JSON.stringify(newValue));
      

    }
    setStopoverChecked(false);

  }
 
  const handleSelectChange = (newValue) => {
    setSelectedLocation2(newValue);
    setStopoverChecked(false);

  };
  const handleSelectChange2 = (newValue) => {
    setSelectedLocation(newValue);
    setStopoverChecked(false);

  };
  const fetchAllCitiesWithAirports = () => {
    try {
        const filteredAirports = airportsData.filter(airport => 
            airport.type === "large_airport"
        );

        const uniqueIataCodes = new Set();
        const transformedAirportOptions = [];
        filteredAirports.forEach(airport => {
            if (!uniqueIataCodes.has(airport.iata_code)) {
                uniqueIataCodes.add(airport.iata_code);
                transformedAirportOptions.push({
                    label: airport.name + " - " + airport.iata_code + " - " + airport.municipality,
                    value: airport.iata_code
                });
            }
        });

        setOptionsLocation3(transformedAirportOptions);
    } catch (error) {
        console.error("Error processing airports:", error);
    }
};


  
  return (
    <>
            <div>
            <div className="overflowFlight mt-0">
            {selectedButton==='center' &&
       <FormControl component="fieldset" className='mt-0'>
                <RadioGroup 
                  row
                  aria-label="trip" 
                  name="tripOption" 
                  value={tripOption} 
                  onChange={e => setTripOption(e.target.value)}
                >
                  <FormControlLabel value="RoundTrip" control={<Radio color="default" style={{ color: '#E2B60A' }} />
} label="Round Trip" />
                  <FormControlLabel value="OneWay" control={<Radio color="default" style={{ color: '#E2B60A' }} />
} label="One Way" />
                  <FormControlLabel value="Multicity" control={<Radio color="default" style={{ color: '#E2B60A' }} />
} label="Multicity" />
       
                </RadioGroup>
              </FormControl>
              }


              <LocalizationProvider dateAdapter={AdapterDayjs} className="overflowFlight">
      <DemoContainer className="overflowFlight" onWheel={() => document.activeElement.blur()}
        components={[ 'TextField'
        ]}
      >
        
<Select 
    className="dark  ml-3"
    placeholder={'From (Country or airports)'}
    options={optionsLocation2}
    value={selectedLocation2}
    onChange={handleSelectChange}
    isClearable={isClearable2}
    menuPortalTarget={document.body}
    styles={{
      container: (provided) => ({
          ...provided,
          minWidth: '250px',
          maxWidth: '250px',
          // Asegura que el contenedor ocupe todo el ancho de su contenedor padre.
      }),
      control: (provided) => ({
          ...provided,
          minWidth: '250px',
          maxWidth: '250px', // Esto hará que el control siempre ocupe el 100% del ancho del contenedor.
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      option: (provided, state) => ({
          ...provided,
          color: state.isFocused ? 'black' : 'black'
      }),
      singleValue: (provided, state) => ({
          ...provided,
          color: 'black'
      }),
      multiValue: (provided) => ({
          ...provided,
          backgroundColor: '#e1e1e1',
          color: 'black'
      }),
      multiValueLabel: (provided) => ({
          ...provided,
          color: 'black'
      }),
      multiValueRemove: (provided) => ({
          ...provided,
          color: 'black',
          ':hover': {
              backgroundColor: 'red',
              color: 'white',
          }
      }),
  
    }}
    theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: 'transparent',
        },
    })}
    formatOptionLabel={({ label }) => {
      const lastDashIndex = label.lastIndexOf(" - ");
      if (lastDashIndex !== -1) {
          const beforeMunicipality = label.substring(0, lastDashIndex + 3); // +3 para incluir " - "
          const municipality = label.substring(lastDashIndex + 3);
          return (
              <div>
                  {beforeMunicipality}
                  <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                      {municipality}
                  </span>
              </div>
          );
      }
      return label;
  }}
    
/>

{selectedButton==='center' &&

  <Select 
    className="dark ml-3"
    placeholder={'To (Country or airports)'}
    label={'To (Country or City)'}
    options={optionsLocation}
    value={selectedLocation}
    onChange={handleSelectChange2}
    isClearable={isClearable2}
    
    menuPortalTarget={document.body}

    styles={{
      container: (provided) => ({
        ...provided,
        minWidth: '250px',
        maxWidth: '250px',
        // Asegura que el contenedor ocupe todo el ancho de su contenedor padre.
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '250px',
        maxWidth: '250px', // Esto hará que el control siempre ocupe el 100% del ancho del contenedor.
    }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'black' : 'black'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'black'
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#e1e1e1',
            color: 'black'
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'black'
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'black',
            ':hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        }),
    }}
    theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: 'transparent',
        },
    })}
    formatOptionLabel={({ label }) => {
      const lastDashIndex = label.lastIndexOf(" - ");
      if (lastDashIndex !== -1) {
          const beforeMunicipality = label.substring(0, lastDashIndex + 3); // +3 para incluir " - "
          const municipality = label.substring(lastDashIndex + 3);
          return (
              <div>
                  {beforeMunicipality}
                  <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                      {municipality}
                  </span>
              </div>
          );
      }
      return label;
  }}
/>
}

<ThemeProvider theme={theme}>
<DatePicker
  label="Depart (MM-DD-YYYY)"
  disablePast='true'
  reduceAnimations='true'
  className='white-background common-input-size'
  value={selectedDate}
  onChange={handleNextDeparture}
  format="MM-DD-YYYY"
  views={["year", "month", "day"]}
  variant='filled'
  closeOnSelect='true'
  slotProps={{
    textField: {
      variant: 'filled',
      placeholder: 'date',
      disableUnderline: true,
    }
  }}
  style={{ borderRadius: "0px" }}  // Estilo para el contenedor del DatePicker
  InputProps={{
    style: { borderRadius: "0px", borderColor: "normal" // Establecer el color normal del borde
  }  // Estilo para la entrada interna
  }}
  InputLabelProps={{
    style: { borderRadius: "0px" }  // Estilo para la etiqueta interna
  }}
/>
</ThemeProvider>
<TextField
  size="small"
  variant="filled"
  type="text"
  value={marginDays}
  label="+/- days before or later" // Usamos ternaria para determinar el texto del label
  onChange={e =>{handleNextMargin(e.target.value)}
}
  className="white-background common-input-size"
  InputProps={{
    inputProps: {
      maxLength: 2,  // Limita a 5 caracteres si es necesario
    },
    style: { borderRadius: 0 }  // Estilo para quitar el borde redondeado
  }}
  InputLabelProps={{
    style: { borderRadius: 0 }  // Estilo para quitar el borde redondeado
  }}
/>

{(tripOption === 'RoundTrip' || tripOption === 'Multicity') && 
  // Tu componente o lógica específica para "One Way" aquí

<TextField
  size="small"
  variant="filled"
  type="text"
  value={tripDuration}
  onChange={e =>{handleTripDurationChange(e.target.value)}}
  label="how many days?" // Usamos 'label' en lugar de 'placeholder'
  className="white-background common-input-size"
  InputProps={{
    inputProps: {
      maxLength: 3,  // Limita a 5 caracteres si es necesario
    },
    style: { borderRadius: 0 }  // Estilo para quitar el borde redondeado
  }}
  InputLabelProps={{
    style: { borderRadius: 0 }  // Estilo para quitar el borde redondeado
  }}
/> 
}    
      </DemoContainer>
    </LocalizationProvider> 
         

{(tripOption === 'Multicity') && 
     
        <div className='common-input-size'>
          {/* Aquí puedes agregar más componentes o campos cuando se activa la opción de hacer escalas */}

  <Select 
  
    id="select-input"
    className="dark mt-2 common-input-size"
    placeholder={'Add (City / Countries)'}
    options={transformedCountries2}
    isMulti // Esto lo convierte en un multi-select
    isClearable={isClearable2}
    onChange={handleNext3}
    value={selected3}
    menuPortalTarget={document.body}
    filterOption={customFilterOption}

    styles={{
      container: (provided) => ({
        ...provided,
        minWidth: '250px',
        maxWidth: '250px',
        // Asegura que el contenedor ocupe todo el ancho de su contenedor padre.
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '250px',
        maxWidth: '250px', // Esto hará que el control siempre ocupe el 100% del ancho del contenedor.
    }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
      option: (provided, state) => ({
        
          ...provided,
          backgroundColor: state.data.label === 'Recommended' || state.data.label === 'Others' ? '#9F9F9F' : (state.isFocused ? provided.backgroundColor : provided.backgroundColor),
          fontStyle: state.data.label === 'Recommended'  || state.data.label === 'Others' ? 'italic' : provided.fontStyle,
          color: state.isFocused ? 'black' : 'black',
          pointerEvents: state.data.label === 'Recommended' || state.data.label === 'Others' ? 'none' : undefined, // Esto evitará que "Recomendados" sea seleccionable
      }),
      singleValue: (provided, state) => ({
          ...provided,
          color: 'black'
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#e1e1e1',
        color: 'black',
        maxWidth: 'calc(100% - 20px)', // Esto limitará el tamaño de cada valor seleccionado
        overflow: 'hidden', // Opcionalmente, puedes ocultar el exceso de texto
      }),
      multiValueLabel: (provided) => ({
          ...provided,
          color: 'black'
      }),
      multiValueRemove: (provided) => ({
          ...provided,
          color: 'black',
          ':hover': {
              backgroundColor: 'red',
              color: 'white',
          }
      }),
  }}
  
    theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: 'transparent',
        },
    })}
    formatOptionLabel={({ label }) => {
      const lastDashIndex = label.lastIndexOf(" - ");
      if (lastDashIndex !== -1) {
          const beforeMunicipality = label.substring(0, lastDashIndex + 3); // +3 para incluir " - "
          const municipality = label.substring(lastDashIndex + 3);
          return (
              <div>
                  {beforeMunicipality}
                  <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                      {municipality}
                  </span>
              </div>
          );
      }
      return label;
  }}
/>

 
</div>        
      }

        <FormControlLabel className='mt-3'
          control={
            <Checkbox 
              checked={selectedButton === 'center'}
              onChange={handleCheckboxChange}
              style={{ color: "#E2B60A" }}
            />
          }
          label="I know my next destination"
        />
            <ThemeProvider theme={theme}>
      <div className='mt-0' onClick={handleNext}>
        <button className='whiteColor'>Let magic come <ArrowRightCircle color='#E2B60A' size={25} /></button>
        {errorMessage && <p2 className="error-message">{errorMessage}</p2>}
        </div>
    </ThemeProvider>
      
      {tripOption === 'Multicity' && !stopoverChecked &&
  <>
    <p className='mt-2 aquitest'>
      Click and check these countries, they are the most common stopovers of your final destination:
      <br></br>
      <div style={{ display: 'flex', placeContent: 'center' }}>
        {postsX.map((item, index) => {
          if (toRotate2.includes(item.stopover)) {
            return (
              <span key={index} style={{ marginRight: '5px' }}>
                <div style={{ position: 'relative', width: '25px', paddingBottom: '100%' }}>
                  <a 
                    href="#" 
                    onClick={(e) => {
                      e.preventDefault();
                      // Resetear la variable de estado aquí
                      localStorage.setItem('destination', null);
                      localStorage.setItem('selecteds', null);


                      // Luego redirigir
                      const url = `/CountrySelected/${item.stopover}`;
                      window.open(url, '_blank');
                    }}
                  >
                    <div className={`flag-icon4 flag-icon-${countryIcons[item.stopover] ? countryIcons[item.stopover].toLowerCase() : 'default'}`} 
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    </div>
                  </a>
                </div>
              </span>
            );
          }
          return null;
        })}
      </div>
    </p>
  </>
}





      {stopoverChecked && tripOption === 'RoundTrip' &&
 <div class="topPanel">
    <WidgetComponent3  from={getCountryName(selectedLocation2.value)} to={getCountryNameDestination(selectedLocation.value)} departure={selectedDateBefore + "_" + selectedDateAfter} containerId='1' affilId='jomajolajomajolawidget' vuelta={returnDateBefore + "_" + returnDateAfter}/>

  </div> 
      }

{stopoverChecked && tripOption === 'OneWay' &&
 <div class="topPanel">
    <WidgetComponent3  from={getCountryName(selectedLocation2.value)} to={getCountryNameDestination(selectedLocation.value)} departure={selectedDateBefore + "_" + selectedDateAfter} containerId='1' affilId='jomajolajomajolawidget' vuelta=""/>

  </div> 
      }
      
  {stopoverChecked && tripOption === 'Multicity' && selected3 && selected3.length > 0  &&
        <div>
          {/* Aquí puedes agregar más componentes o campos cuando se activa la opción de hacer escalas */}
          <div class="containertopPanel">

  <div class="topPanel">
    <FlightInfo dateFrom={selectedDateBefore} dateTo={selectedDateAfter} flyFrom={getCountryName(selectedLocation2.value)} locations={selected3} destination={getCountryName(selectedLocation.value)} daysMin={parseFloat(tripDuration) - parseFloat(selected3 ? selected3.length : 0)*3} daysMax={parseFloat(tripDuration) - parseFloat(selected3 ? selected3.length : 0 )}/>

  </div>
  
</div>        </div>
      }
          

      
      </div>
      
        </div>
      
            </>
  );
}
