import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";

//      <NavBar />       <Contact />



function App() {
  return (

    
    <div className="rotate-device">
      <Banner />

    </div>
  );
}

export default App;
