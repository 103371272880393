import React, { useState, useEffect } from 'react';
import { InputCircle } from "./InputCircle";
import { ArrowRight } from 'react-bootstrap-icons';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from 'react-tooltip'
import { IconButton } from '@mui/material';


  export const Skills = () => {
    //https://react-tooltip.com/docs/getting-started

    const theme = createTheme({
      palette: {
        neutral: {
          main: '#2290ac',
          contrastText: '#fff',
        },
      },
    });
    useEffect(() => {
      if (window.location.hash === '#likes') {
        const element = document.getElementById('likes');
        if (element) element.scrollIntoView();
      }
    }, [window.location.hash]);
    
    return (
      <section className="skill" id="likes">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="skill-bx">
              <h3>Wishes & Likes</h3>
<p>
  Personalize your trip to find the best destination for you. Help us defining your likes
  <br></br>
  <span role="button" onClick={() => window.location.replace("/#projects")}>
  <button className="rayado">Skip</button>
</span>
</p>

                        <div className='social-icon'>
                          <h4 id="Aventureh4">Aventure
                          <a  data-tooltip-id="my-tooltip"  style={{ backgroundColor: "#192839", borderRadius: "0px", border: "0px"}}>
                          <IconButton style={{color: 'white'}}>
                              <InfoIcon />
                            </IconButton></a>
                           <Tooltip 
                              id="my-tooltip" 
                              style={{ backgroundColor: "rgba(226, 182, 10, 1)"}}
                              className="tooltip" 
                              anchor="top"  // You can adjust the anchor position as needed
                              content="Adrenaline in the blood"
                              >
                              <IconButton style={{ color: 'white' }}>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>                         
                          </h4>
                          <div className="circle-container"> 
                          <InputCircle nombre="Aventure"></InputCircle>
                          </div>
                        </div>
                          <div className='social-icon'>
                            <h4 id="Natureh4">Nature
                            <a data-tooltip-id="my-tooltip2"  style={{ backgroundColor: "#192839", borderRadius: "0px", border: "0px"}}>
                          <IconButton style={{color: 'white'}}>
                              <InfoIcon />
                            </IconButton></a>
                            <Tooltip 
                              id="my-tooltip2" 
                              style={{ backgroundColor: "rgba(226, 182, 10, 1)"}}
                              className="tooltip" 
                              anchor="top"  // You can adjust the anchor position as needed
                              content="I love nature, you would filter in the next section for beach or mountain"
                              >
                              <IconButton style={{ color: 'white' }}>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip> 
                            </h4>
                            <div className="circle-container"> 
                            <InputCircle nombre="Nature"></InputCircle>
                            </div>
                          </div>
                          <div className='social-icon'>
                          <h4 id="Historicalh4">Historical
                          <a data-tooltip-id="my-tooltip3"  style={{ backgroundColor: "#192839", borderRadius: "0px", border: "0px"}}>
                          <IconButton style={{color: 'white'}}>
                              <InfoIcon />
                            </IconButton></a>
                          <Tooltip 
      id="my-tooltip3" 
      style={{ backgroundColor: "rgba(226, 182, 10, 1)"}}
      className="tooltip" 
      anchor="top"  // You can adjust the anchor position as needed
      content="If do you like the History this section is for you"
      >
      <IconButton style={{ color: 'white' }}>
        <InfoIcon />
      </IconButton>
    </Tooltip> 
                          
                          
                          </h4>
                          <div className="circle-container"> 
                          <InputCircle nombre="Historical"></InputCircle>
                          </div>
                          </div>
                          <div className='social-icon'>
                          <h4 id="Entertainmenth4">Entertainment
                          <a data-tooltip-id="my-tooltip4"  style={{ backgroundColor: "#192839", borderRadius: "0px", border: "0px"}}>
                          <IconButton style={{color: 'white'}}>
                              <InfoIcon />
                            </IconButton></a>
                          <Tooltip 
      id="my-tooltip4" 
      style={{ backgroundColor: "rgba(226, 182, 10, 1)"}}
      className="tooltip" 
      anchor="top"  // You can adjust the anchor position as needed
      content="Entertainment is the shows"
      >
      <IconButton style={{ color: 'white' }}>
        <InfoIcon />
      </IconButton>
    </Tooltip> 
                          </h4>
                          <div className="circle-container"> 
                          <InputCircle nombre="Entertainment"></InputCircle>
                          </div>
                          </div>
                          <div className='social-icon'>
                          <h4 id="Arth4">Art&Cultural
                          <a data-tooltip-id="my-tooltip5" style={{ backgroundColor: "#192839", borderRadius: "0px", border: "0px"}}>
                          <IconButton style={{color: 'white'}}>
                              <InfoIcon />
                            </IconButton></a>
                          <Tooltip 
      id="my-tooltip5" 
      style={{ backgroundColor: "rgba(226, 182, 10, 1)"}}
      className="tooltip" 
      anchor="top"  // You can adjust the anchor position as needed
      content="Art in the streets, is a country very own, multi cultural, religious"
      >
      <IconButton style={{ color: 'white' }}>
        <InfoIcon />
      </IconButton>
    </Tooltip> 
                          </h4>
                          <div className="circle-container"> 
                          <InputCircle nombre="Art"></InputCircle>
                          </div>
                          </div>
                          <div className='social-icon'>
                          <h4 id="Nightlifeh4">Nightlife
                          <a data-tooltip-id="my-tooltip6"  style={{ backgroundColor: "#192839", borderRadius: "0px", border: "0px"}}>
                          <IconButton style={{color: 'white'}}>
                              <InfoIcon />
                            </IconButton></a>
                            <Tooltip 
      id="my-tooltip6" 
      style={{ backgroundColor: "rgba(226, 182, 10, 1)"}}
      className="tooltip" 
      anchor="top"  // You can adjust the anchor position as needed
      content="The night is yours"
      >
      <IconButton style={{ color: 'white' }}>
        <InfoIcon />
      </IconButton>
    </Tooltip> 
                          </h4>
                          <div className="circle-container"> 
                          <InputCircle nombre="Nightlife"></InputCircle>
                          </div>
                          </div>
                          
                          <h1></h1>
                          <ThemeProvider theme={theme}>
                          <div onClick={() => window.location.replace("/#projects")}>
                          <Button variant="contained"  color='neutral' className='yellowBackground'>Go <ArrowRight size={25} /></Button>
                          </div> 
                          </ThemeProvider>
    
                                            </div>
                </div>
            </div>
        </div>
      </section>
          )
    
       
  }

