import React,  { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Budget } from './Budget';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {FeatureCard} from './feature-card';
import axios from 'axios';
import { Stopover } from './Stopover';
import { PublicTransport } from './PublicTransport';
import Events from './Events';
import Events2 from './Events2';
import { Footer } from './Footer';
import Party from './Party';
import { ItineraryList } from './ItineraryList';
import { StopOverFlight } from './stopOverFlight';
import { Tips } from './Tips';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TipsTXT } from './TipsTXT';
import MapIcon from '@mui/icons-material/Map';
import NightlifeIcon from '@mui/icons-material/Nightlife';

export function CountryDetail() {
  const { pais } = useParams();
  const [backgroundUrl, setBackgroundUrl] = React.useState('');
  const months = JSON.parse(localStorage.getItem('selectedMonths')) || '';
  const days = JSON.parse(localStorage.getItem('tripDuration')) || '';
  const location = JSON.parse(localStorage.getItem('location')) || '';
  const selectedLocation = location.label;
  const selectedMonths = months.label;
  const itineraryListElement = <ItineraryList pais={pais}></ItineraryList>;
  const culturalEventElement = <Events2 choose='culturalEvent'pais={pais} month={selectedMonths}></Events2>;
  const ticketmasterElement = <Events choose='culturalEvent' pais={pais} month={selectedMonths}/>;
  const [expandedAccordion, setExpandedAccordion] = React.useState('none');
  const mapAccordionRef = React.useRef(null);
  const budgetAccordionRef = React.useRef(null);
  const entertainmentAccordionRef = React.useRef(null);
  
  const expandAccordion = (id) => {
    window.location.href = id;  // This will also change the URL, you might not need this if you just want to scroll

    setExpandedAccordion(id);

    switch (id) {
      case '#map':
        mapAccordionRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case '#budget2':
        budgetAccordionRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case '#entertainment':
        entertainmentAccordionRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
};

  const [open, setOpen] = React.useState(false);
  

  
 
  
  
 
  const theme = createTheme({
    status: {
      vuelos: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#0971f1',
        darker: '#053e85',
      },
      clima: {
        main: '#64748B',
        contrastText: '#fff',
      },
      yellow: {
        main: '#e2b60a',
        contrastText: '#fff',
      },
      orange: {
        main: '#ffa726',
        contrastText: '#fff',
      },
    },
  });

  const details = [
  
    {
      choose: "interest"
    },    
    {
      choose: "nature"
    },
    {
      choose: "food"
    }
];

const config = {
  headers:{
    Authorization: process.env.REACT_APP_TOKEN_PEXELS,
  }
};

//URL llamada API
const url = "https://api.pexels.com/v1/search?query="+ pais + "&orientation=landscape" +"&per_page=1";

useEffect(() => {
  if (!months || !location) {
    window.location.href = "/";
  }


    handleContinue();
}, [pais]);
//Metodo que lo junta todo y hace la llamada API
  const handleContinue = async () => {
    await axios.get(url, config)
      .then(response => {        
        console.log(response.data.photos);
        setBackgroundUrl(response.data.photos[0].src.landscape);
      })
      .catch(error => {
        console.error(error);
      });
  };



return (
    <>
      <div className="relative flex content-center items-center justify-center pt-16 pb-32 testeo">
      <div className="absolute top-0 h-full w-full opacityBackground" style={{ backgroundImage: `url('${backgroundUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
            <h1 className="-mt-10 blackColor underline ">{pais}</h1>
             <p className="mb-56 mt-16 ml-10 mr-10 opacity-80 italic"> {}</p>
            </div>
          </div>
        </div>
        <Zoom
        in={true}
        timeout={{enter: 500, exit: 500}}
        unmountOnExit>
      <Box sx={{ '& > :not(style)': { m: 1 } }} className='placeOver6'>
      <ThemeProvider theme={theme}>
            <Fab variant="extended" color="yellow" onClick={() => expandAccordion('#flights')} >
          Flights   
          <AirplaneTicketIcon sx={{ mr: 1 }} />
          Na
        </Fab>
        </ThemeProvider>
      </Box>
    </Zoom>
    <Zoom
        in={true}
        timeout={{enter: 500, exit: 500}}
        unmountOnExit>
      <Box sx={{ '& > :not(style)': { m: 1 } }} className='placeOver5'>
      <ThemeProvider theme={theme}>
      <Fab variant="extended" color="yellow" onClick={() => expandAccordion('#budget2')}>
  Budget & Tips   
  <LightbulbIcon sx={{ mr: 2 }} />
  Na
</Fab>

        </ThemeProvider>
      </Box>
    </Zoom>
    <Zoom
        in={true}
        timeout={{enter: 500, exit: 500}}
        unmountOnExit>
      <Box sx={{ '& > :not(style)': { m: 1 } }} className='placeOver4'>
      <ThemeProvider theme={theme}>
      <Fab variant="extended" color="yellow" onClick={() => expandAccordion('#entertainment')}>
  Entertainment  
  <NightlifeIcon sx={{ mr: 2 }} />
  Na
</Fab>

        </ThemeProvider>
      </Box>
    </Zoom>

    <Zoom
        in={true}
        timeout={{enter: 500, exit: 500}}
        unmountOnExit>
      <Box sx={{ '& > :not(style)': { m: 1 } }} className='placeOver7'>
      <ThemeProvider theme={theme}>
      <Fab variant="extended" color="yellow" onClick={() => expandAccordion('#map')}>
  Map  
  <MapIcon sx={{ mr: 2 }} />
  Na
</Fab>

        </ThemeProvider>
      </Box>
    </Zoom>
      </div>     
      <section className="test bg-gray-50 px-4 pb-20 pt-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          
            {details.map(({ choose}) => (
              <FeatureCard
                choose={choose}
                pais={pais}
              />
            ))}
            
          </div>
  
          <div className="grid grid-cols-1  mt-8 advanced-bx">
          <Accordion ref={mapAccordionRef} id="#map" defaultExpanded={false} expanded={expandedAccordion === '#map'} onChange={() => setExpandedAccordion(expandedAccordion === '#map' ? 'none' : '#map')}
 className='advanced-bx'>
      <AccordionSummary className='advanced-bx'
        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
        
        >
        <h4 >Map</h4>

      </AccordionSummary>
      <AccordionDetails className='advanced-bx'>
      <div className="grid grid-cols-1 gap-12 md:grid-cols-1 lg:grid-cols-1">
          {itineraryListElement
          }

            </div>

      </AccordionDetails>
    </Accordion>

  </div>
          <div className="grid grid-cols-1  mt-8 advanced-bx">
          <Accordion ref={budgetAccordionRef} id='#budget2' defaultExpanded={false} expanded={expandedAccordion === '#budget2'} onChange={() => setExpandedAccordion(expandedAccordion === '#budget2' ? 'none' : '#budget2')}
 className='advanced-bx'>
      <AccordionSummary className='advanced-bx'
        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
      >
        <h4 >Budget & Tips</h4>
      </AccordionSummary>
      <AccordionDetails className='advanced-bx'>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-3 advanced-bx">

      <Budget pais={pais} />
      <PublicTransport pais={pais}></PublicTransport>
      <TipsTXT pais={pais} />

      </div>

      </AccordionDetails>
    </Accordion>

  </div>
          



  <div className="grid grid-cols-1  mt-8 advanced-bx">
  <Accordion ref={entertainmentAccordionRef} id="#entertainment" defaultExpanded={false} expanded={expandedAccordion === '#entertainment'} onChange={() => setExpandedAccordion(expandedAccordion === '#entertainment' ? 'none' : '#entertainment')} className='advanced-bx'>
      <AccordionSummary className='advanced-bx'
        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
      >
        <h4 >Entertainment</h4>
      </AccordionSummary>
      <AccordionDetails className='advanced-bx'>
        <div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-3 advanced-bx">
          {/*culturalEventElement*/}
          <Party
            choose='nature'
            pais={pais}
            month={selectedMonths}
          />
          {ticketmasterElement}
        </div>
        </div>
      </AccordionDetails>
    </Accordion>

  </div>
        
           {/*< <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
            Stopover pais={pais} location={selectedLocation}></Stopover>

</div>*/}

<div class="containertopPanel">
  <div class="topPanel" id='homeFlights'>
    <StopOverFlight pais={pais} month={selectedMonths} location={selectedLocation} days={days} />
  </div>
</div>



        </div>

      </section>
      <div>
    </div>
      <Footer></Footer>

    </>
  );
}
export default CountryDetail;
